export default {
  exit: 'Exit',
  zoom: 'Zoom',
  clear: 'Clear',
  reset: 'Reset',
  invert: 'Invert',
  filters: 'Filters',
  compare: 'Compare',
  features: 'Features',
  contrast: 'Contrast',
  caseInfo: 'Case Info',
  caseReviewComment: 'Case Comment',
  grayScale: 'Gray Scale',
  brightness: 'Brightness',
  fullScreen: 'Full Screen',
  createdDate: 'Created Date',
  modifiedDate: 'Modified Date',
  downloadFormat: 'Select download format',
  // dicom
  pan: 'Pan',
  WWOrWC: 'WW/WC',
  playClip: 'Play Clip',
  stopClip: 'Stop Clip',
  pixelProbe: 'Pixel Probe',
  stackScroll: 'Stack Scroll',
  clearScreen: 'Clear Screen',
  rectangleRoi: 'Rectangle ROI',
  ellipticalRoi: 'Elliptical ROI',
  lengthMeasurement: 'Length Measurement',
  angleMeasurement: 'Angle Measurement',
  // viewer-toolbar
  png: '.png',
  home: 'Home',
  jpeg: '.jpeg',
  tiff: '.tiff',
  snapshot: 'Snapshot',
  navigator: 'Navigator',
  drawLooped: 'Draw Looped',
  drawFreehand: 'Draw Freehand',
  editCaseNotes:'Edit Case Notes',
  addNewFeature:'Add a new feature for annotation',
  editDiagnosticFeatures:'Edit Diagnostic Features',
  drawAnnoatation:'Draw Annotation',
  edit: 'Edit',
  annotationsList: 'Annotations List',
  loopedAnnotation: 'Looped Annotation',
  squareAnnotation: 'Square Annotation',
  freehandAnnotation: 'Freehand Annotation',
  showAllAnnotations: 'Show All Annotations',
  downloadScreenshot: 'Download Screenshot',
  compareSlides: 'Compare Slides',
  previousSlide: 'Previous Slide',
  nextSlide: 'Next Slide',
  noSlidesAvailable: 'No Slides available',
  circleAnnotation: 'Circle Annotation',
};
