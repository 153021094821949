const urls = {
    base: '/api/v1',
    login: '/log-in',
    googleLogin: '/login',
    auth: '/auth',
    all: '/all',
    home: '/home',
    get: '/get',
    user: '/user',
    admin: '/admin',
    forgotPassword: '/forgot-password',
    validateOtp: '/validate-otp',
    reset: '/reset',
    resetPassword: '/reset-password',
    changePassword: '/change-password',
    inviteuser: '/inviteuser',
    userRole: '/userrole/all',
    getUsers: '/user/all',
    getAllUsers: '/user/allusers',
    userByToken: '/userbytoken',
    applicationType: '/applicationtype',
    testDb: '/database/testconfig',
    dbTypes: '/databasetype/all',
    dbConfig: '/dbconfig',
    tenant: '/tenant',
    cloudProviders: '/cloudproviders/all',
    testResource: '/cloud/testconfig',
    cloudConfig: '/cloudconfig',
    pagination: '/pagination',
    dns: '/dns',
    checkDns: '/check-dns',
    fetch: '/fetch',
    rest: '/rest',
    upload: '/upload',
    slidebox: '/slidebox',
    folders: '/folders',
    folder: '/folder',
    userManagement: '/usermanagement',
    slides: '/slides',
    slide: '/slide',
    folderSlides: '/folderslides',
    multiple: '/multiple',
    caseSection: '/case-sections',
    caseSystem: '/case-system',
    caseSpecies: '/case-species',
    copy: '/copy',
    move: '/move',
    sharedDocs: '/shared-with-documents',
    slideLibrary: '/slidelibrary',
    shareDocs: '/share-document',
    shareGroup: '/share-group',
    shareGroupMember: '/share-group-member',
    diagnosis: '/diagnosis',
    highyield: '/highyield',
    sections: '/sections',
    section: '/section',
    cases: '/cases',
    casedata: '/casedata',
    slideby: '/slideby',
    // admin quiz
    quiz: '/quiz',
    members: '/members',
    member: '/member',
    publish: '/publish',
    published: '/published',
    unpublish: '/unpublish',
    questions: '/questions',
    update: '/update',
    updateAll: '/updateAll',
    answers: '/answers',
    correctAnswer: '/correctAnswer',
    userGroups: '/user-groups',
    userGroup: '/user-group',
    questionBank: '/questionBank',
    // user quiz
    myQuizzes: '/myQuizzes',
    submitAnswers: '/submitAnswers',
    submitAnswer: '/submitAnswer',
    fileUploader: '/fileuploader',
    fileUpload: '/fileUpload',
    file: '/file',
    filter: '/filter',
    resources: '/resources',
    actions: '/actions',
    listActions: '/list_actions',
    actionTypes: '/action_types',
    role: '/userrole',
    policies: '/policies',
    rolePolicy: '/role',
    add: '/add',
    quizUsers: '/quiz-users',
    status: '/status',
    membersAnswers: '/membersAnswers',
    evaluate: '/evaluate',
    submitFeedback: '/submitFeedback',
    presentation: 'presentation',
    presentations: '/presentations',
    folderPresentation: '/folder-presentation',
    presentationDoc: '/folder-presentation-document',
    sharedByMe: '/shared-by-me',
    sharedByOthers: '/shared-by-others',
    share: '/share',
    users: '/users',
    sectionUsers: '/highyield-section-users',
    highyieldCase: '/highyield-case',
    caseUsers: '/highyield-case-users',
    order: '/order',
    myHighyieldSections: '/myHighyieldSections',
    myHighyieldCases: '/myHighyieldCases',
    annotations: '/annotations',
    myAnnotations: '/my-annotations',
    mySlideAnnotation: '/my-annotations-slide',
    active: '/active',
    group: '/group',
    allGroups: '/allGroups',
    multipleGroups: '/multiple-groups',
    multipleUsers: '/multiple-users',
    sharedGroups: '/shared-groups',
    sharedMembers: '/shared-members',
    chiefEditors: '/chief-editors',
    resendOtp: '/resend-otp',
    speciality: '/speciality',
    subSpeciality: '/subspeciality',
    profession: '/profession',
    pptslides: '/pptslides',
    publicUserAccess: '/public-user-access',
    publicUserInvite: '/public-user-invite',
    slidesByToken: '/slides-by-public-token',
    import: '/import',
    clone: '/clone',
    presentationByToken: '/presentation-by-public-token',
    emailConfig: '/emailconfig',
    email: '/email',
    testConfig: '/testconfig',
    signUp: '/sign-up',
    library: '/library',
    quizByToken: '/quiz-by-public-token',
    annotationsByToken: '/annotations-by-public-token',
    emailProps: '/emailprops',
    selfAssessmentSubmit: '/selfass-submitAnswers',
    parentFolders: '/parent-folders',
    allAnnotations: '/all-slide',
    casedescriptions: '/casedescriptions',
    search: '/search',
    countries: '/countries',
    recycle: '/recycle',
    pptUpload: '/pptUploadAsync',
    ppt: '/ppt',
    importSlide: '/importslide',
    slideUpload: '/slideUpload',
    sasToken: '/sas-token',
    reorder: '/reorder',
    shorturl: '/shorturl',
    groupChat: '/groupchat',
    allUsers: '/allUsers',
    message: '/message',
    archive: '/archive',
    poll: '/poll',
    results: '/results',
    draft: '/draft',
    webdata: '/webhome',
    webabout: '/web/about',
    webcontact: '/web/contact',
    webhome: '/web/home',
    type: '/type',
    config: '/config',
    analysis: '/analysis',
    scoreanalysis: '/scoreanalysis',
    shareToGroups: '/shared-to-groups',
    details: '/details',
    clientDetails: '/clientdetails',
    clientToken: '/clientToken',
    submitNounce: '/submitnounce',
    quizDoc: '/quiz-document',
    public: '/public',
    assignedSections: '/assigned-sections',
    assignedCases: '/assigned-cases',
    multiMember: '/multi-member',
    app: '/app',
    groups: '/groups',
    redisChat: '/redis-chat',
    refreshGroups: '/refreshgroups',
    messages: '/messages',
    unsubscribe: '/unsubscribe',
    updateSlide: '/updateSlide',
    markasread: '/markasread',
    topic: '/topic',
    sendAuth: '/sendAuth',
    donateUser: '/donate-user',
    donation: '/donation',
    apiConfig: '/apiconfig',
    permissions: '/permissions',
    certificateContent: '/cert-content',
    transaction: '/transaction',
    conference: '/conference',
    lecture: '/lecture',
    video: '/video',
    attachments: '/attachments',
    attach: '/attach',
    conf: '/conf',
    feedback: '/feedback',
    qa: '/qa',
    allInfo: '/all-info',
    comments: '/comments',
    reply: '/reply',
    subscription: '/subscription',
    plans: '/plans',
    braintree: '/braintree',
    subscriptionPayment: '/subscription-payment',
    payment: '/payment',
    consumption: '/consumption',
    unShare: '/unshare',
    subscriptionTransaction: '/subscription-transaction',
    subscriptionHistory: '/subscription-history',
    stopRecurring: '/stop-recurring',
    invitations: '/invitations',
    category: '/category',
    parent: '/parent',
    poster: '/poster',
    faculty: '/faculty',
    vendor: '/vendor',
    byUser: '/byUser',
    emeet: '/emeet',
    invite: '/invite',
    fortype: '/fortype',
    accessInfo: '/access-info',
    validate: '/validate',
    schedule: '/schedule',
    allByRole: '/allByRole',
    byRole: '/byRole',
    subsections: '/subsections',
    assigned: '/assigned',
    new: '/new',
    getroles: '/getroles',
    push: '/push',
    byTenantAdmin: 'bytenantadmin',
    profile: '/profile',
    postassessment: '/postassessment',
    preassessment: '/preassessment',
    activity: '/activity',
    updateStatus: '/updateStatus',
    notify: '/notify',
    author: '/author',
    paymentMethod: '/paymentMethod',
    oneDrive: '/oneDrive',
    single: '/single',
    orderCheck: '/ordercheck',
    menuItem: '/menuitem',
    confOwner: '/conf-owner',
    credentials: '/credentials',
    social: '/social',
    links: '/links',
    google: '/google',
    sync: '/sync',
    agenda: '/agenda',
    lecturechat: '/lecturechat',
    introVideos: '/intro-videos',
    landing: '/landing',
    page: '/page',
    zoom: '/zoom',
    signature: '/signature',
    calendar: '/calender',
    conferenceInfo: '/conference-info',
    representative: '/representative',
    representatives: '/representatives',
    getRole: '/getRole',
    exhibits: '/exhibits',
    contribution: '/contribution',
    sub: '/sub',
    selfassMemberAnswers: '/selfass-membersAnswers',
    subscriptionPlan: '/subscriptionplan',
    byUserId: '/byuserid',
    byexcel: '/byexcel',
    differential: '/differential',
    immunoimages: '/immunoimages',
    next: '/next',
    description: '/description',
    question: '/question',
    caseByPublicToken: '/case-by-public-token',
    specific: '/specific',
    bySlideId: '/bySlideId',
    bySource: '/bysource',
    byPollId: '/byPollId',
    options: '/options',
    withUser: '/with-user',
    isShared: '/isShared',
    slideMetadata: '/slide-meta-data',
    byslideId: '/byslideId',
    authors: '/authors',
    audit: '/audit',
    export: '/export',
    news: '/news',

    //new for WTR
    globalrepo: '/globalrepo',
    metadatalist: '/metadatalist',
    allMetadata:'/allMetadata',
    geographicregions: '/geographicregions',
    tumorsite: '/tumorsite',
    addTumorSite: '/addTumorSite',
    updateTumorSite: '/updateTumorSite',
    deleteTumorsite: '/deleteTumorSite',
    getAllTumorSites: '/getAllTumorSites',
    tumorsites: '/tumorsites',
    tumortypes: '/tumortypes',
    tumorsubtypes: '/tumorsubtypes',
    addTumorType: '/addTumorType',
    updateTumorType: '/updateTumorType',
    deleteTumorType: '/deleteTumorType',
    addTumorSubType: '/addTumorSubType',
    updateTumorSubType: '/updateTumorSubType',
    deleteTumorSubType: '/deleteTumorSubType',
    getAllMetadataBySlideIds:'/getAllMetadataBySlideIds',
    getAllCases:'/getAllCases',
    getSlideIdsByCaseDetailId:'/getSlideIdsByCaseDetailId',
    movedToEditorialBox:'/movedToEditorialBox',
    movedToSlideLibrary:'/movedToSlideLibrary',
    shareBackToEditor:'/shareBackToEditor',
    caseslides:'/caseslides',
    metadata: '/metadata',
    addMetadata:'/addMetadata',
    updateMetadata:'/updateMetadata',
    deleteMetadata:'/deleteMetadata',
    inviteUser: '/inviteuser',
    saveguest: '/saveguest',
    guest: '/guest',
    getGuests: '/guest/all',
    userAll: '/user/all',
    saveSharedToEditor:'/saveSharedToEditor',
    searchMetaData: 'search/all',
    contactUs: '/contactus',
    allSharedToEditorFolders:'/getAllSharedToEditorFolders',
    sponsor: '/sponsors',
    caseSlideImages:'/caseSlideImages',
    getSubscriptions: '/subscription/all',
    getAllSlideLibraryCases:'/getAllSlideLibraryCases',
    userListData: 'user/details/all',
    chairpersonUserListData:'user/details/chairpersons',
    updateUser: '/user/updateUser',
    submitCorrespondence: '/submitcorrespondence',    
    specificCohort: '/specificCohort',
    addSpecificCohort: '/addSpecificCohort',
    updateSpecificCohort: '/updateSpecificCohort',
    deleteSpecificCohort: '/deleteSpecificCohort',
    getAllSpecificCohorts: '/getAllSpecificCohorts',
    specificCohorts: '/specificCohorts',
    getContributorEditor:'/getContributorEditor',
    restoreMetadata:'/restoreMetadata',
    specificCohortByTumorSite : '/specificCohortByTumorSite',
    familialDisease: '/familialDisease',
    environmentalSetting: '/environmentalSetting',
    resend: '/resend',
    caseReviewComment: '/caseReviewComment',
    addCaseReviewComment: '/addCaseReviewComment',
    allEditorByTumorSite: '/allEditorByTumorSite',
    allCaseReviewStatus: '/allCaseReviewStatus',
    addCasedata: '/addCasedata',
    getAllCaseDetailsById: '/getAllCaseDetailsById',
    moveBackForRevision:'/moveBackForRevision',
    moveToPrepublicationBox:'/moveToPrepublicationBox',        
    deleteCasedata:'/deleteCasedata',
    changeStatus:'/changeStatus',
    restoreCasedata:'/restoreCasedata',
    deleteMetadataFromRecycle:'/deleteMetadataFromRecycle',
    getExportData:'/getExportData',
    chairpersonListData: 'user/chairperson',
    allEditorList: '/allEditorList',
    userDetailsByTumorSite: '/userDetailsByTumorSite',
    terms:'/terms',
    microscopicGroupFeature : '/microscopicGroupFeature',
    calculateProbabilities : '/calculateProbabilities',
    updatethumbnail: '/updatethumbnail'
  };
  export default urls;
  