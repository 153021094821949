import urls from './urls';

const getUrl = (url, type) => {
  let endPoint = "";
  if (process.env.NODE_ENV === "development") {
    // For local testing
    if(type.port === "9060")
    {
      const base = `http://localhost:${type.port}${type.localEndPoint}`;
      endPoint = url ? `${base}${url}` : base;
    }
    else
    {
      endPoint = url ? `${type.endPoint}${url}` : type.endPoint;
    }
  }
  else {
    // For Dev, staging, uat, prob
    endPoint = url ? `${type.endPoint}${url}` : type.endPoint;
  }
  return endPoint;
};

export const getUserUrl = url => getUrl(url, {
  port: '8080',
  endPoint: urls.userManagement,
  localEndPoint: `${urls.base}${urls.userManagement}`,
});
export const getAnnotationUrl = url => getUrl(url, {
  port: ':9050',
  endPoint: urls.annotations,
  localEndPoint: `${urls.base}${urls.annotations}`,
});
export const getGLobalRepoUrl = url => getUrl(url, {
  port: '9060',
  endPoint: urls.globalrepo,
  localEndPoint: `${urls.base}${urls.globalrepo}`,
});
export const getUploadUrl = url => getUrl(url, {
  port: '8090',
  endPoint: urls.fileUploader,
  localEndPoint: `${urls.base}${urls.fileUploader}`,
});

export const getSlideUrl = url => getUrl(url, {
  port: '8092',
  endPoint: urls.slidebox,
  localEndPoint: `${urls.base}${urls.slidebox}`,
});

