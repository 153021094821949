import axios from 'axios';
import { getAnnotationUrl } from './api-services';
import urls from './urls';

export const saveAnnotationsFact = data => axios.post(getAnnotationUrl(), data);


export const updateAnnotationsFact = data => axios.put(getAnnotationUrl(), data);

export const deleteAnnotationsFact = id => axios.delete(getAnnotationUrl(`/${id}`));

export const reorderAnnotationsFact = (id, source, data) => axios.post(getAnnotationUrl(`${urls.order}/${id}/${source}`), data);

export const getAnnotationById = (id, token) => axios.get(getAnnotationUrl(`/${id}`), {
  headers: {
    authorization: token,
  },
});

export const copyAnnotationsForNewSlide = data => axios.post(getAnnotationUrl(`${urls.copy}`), data);

export const copyGroupChatAnnotationsFact = data => axios.post(getAnnotationUrl(`${urls.groupChat}${urls.copy}`), data);

export const getAnnotationsBySlideFact = (ids, source) => {
    return axios.post(getAnnotationUrl(`${urls.all}${urls.bySource}?source=${source}`), ids);
  };