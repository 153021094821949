import {
  min,
  max,
  map,
  forEach,
  assign,
  cloneDeep,
  findIndex,
  sortBy,
  find,
  filter,
} from 'lodash';
import OpenSeadragon from 'openseadragon';
import viewerMixin from './viewer-mixin';
import {
  ANNOTATION_FREEHAND,
  ANNOTATION_LOOPED,
  ANNOTATION_RECTANGLE,
  ANNOTATION_LINE,
  ANNOTATION_CIRCLE,
} from '../constants/constants-annotation';

export default {
  data() {
    return {
      currentAnno: -1,
      currentAnnoId: null,
      isDrawing: false,
      annoSource: '',
      settingsPosition: null,
      isLoading: false,
      currentpoint: null,
      newPoint: null,
      hoveredPath: null,
    };
  },
  mixins: [viewerMixin],
  mounted() {
    this.addKeydownListner(true);
  },
  beforeDestroy() {
    this.removeKeyDownListner();
  },
  computed: {
    mpp() {
      return this.slideData.mdMpp || 0;
    },
  },
  methods: {
    addKeydownListner(isActive) {
      window.removeEventListener('keydown', () => {
        this.currentAnno = -1;
      });
      window.addEventListener('keydown', event => {
        this.handleKey(event, isActive);
      });
    },
    removeKeyDownListner() {
      window.removeEventListener('keydown', this.handleKey);
    },
    assignAnnotations(data) {
      this.annotations = data;
    },
    reorderAnnotations() {
      let orders = map(this.annotations, 'order');
      orders = sortBy(orders, num => num);
      const payload = [];
      forEach(this.annotations, (val, index) => {
        payload.push({
          order: orders[index],
          annotationId: val.id,
        });
      });
      this.$api
        .reorderAnnotationsFact(this.slideData.id, this.annoSource, payload)
        .then(
          response => {
            if (!this.$handleResponse(response)) return;
            this.annotations = response.data.payload;
            this.currentAnno = findIndex(
              this.annotations,
              item => item.id === this.currentAnnoId,
            );
            this.$toastr(
              'success',
              'Order of annotations changed successfully',
            );
          },
          error => {
            this.$handleError(error);
          },
        );
    },
    getAnnotations(id, search) {
      this.$api
        .getAnnotationsFact(id, this.annoSource, {
          search,
        })
        .then(
          response => {
            if (!this.$handleResponse(response)) return;
            this.annotations = response.data.payload.content;
            this.showAll = false;
          },
          error => {
            this.$handleError(error);
          },
        );
    },
    getAnnotationsByToken(id, userId, token, search) {
      this.$api
        .getAnnotationsByTokenFact(id, this.annoSource, userId, token, {
          search,
        })
        .then(
          response => {
            if (!this.$handleResponse(response)) return;
            this.annotations = response.data.payload.content;
            this.showAll = false;
          },
          error => {
            this.$handleError(error);
          },
        );
    },
    getAnnotationsBySlide(slideIds) {
      this.$api
        .getAnnotationsBySlideFact(
          slideIds,
          this.annoSource,
        )
        .then(
          response => {
            if (!this.$handleResponse(response)) return;
            this.annotations = response.data.payload;
            this.showAll = false;
          },
          error => {
            this.$handleError(error);
          },
        );
    },
    saveDrawing(anno, cb) {
      this.annotation = {
        ...anno,
        rotation: this.rotation,
      };
      if (this.newPoint) {
        this.annotation.points.splice(1, 1, this.newPoint);
      }
      const callBackFn = (response, oldAnno) => {
        if (cb) cb(response, oldAnno);
      };
      if (this.annotation.id) {
        this.updateAnnotation(this.annotation, callBackFn);
      } else {
        this.saveAnnotation(this.annotation, callBackFn);
      }
    },
    updateFlag(annotation) {
      this.updateAnnotation(annotation);
    },
    saveResponse() {
      if (this.path) {
        this.path.annotation = this.annotation;
      }
      this.annotation = null;
      this.path = null;
      this.showSettings = false;
      this.showInfo = false;
      this.newPoint = null;
      this.currentpoint = null;
      this.enableDrawing(false);
      this.initMouseMove();
    },
    saveAnnotation(annotation, cb, hideMsg) {
      this.isLoading = true;
      this.$api.saveAnnotationsFact(annotation).then(
        response => {
          if (!this.$handleResponse(response)) return;
          this.annotation = response.data.payload;
          this.annotations.push(this.annotation);
          if (cb) {
            cb(this.annotation);
          }
          this.saveResponse();
          if (!hideMsg) {
            this.$toastr(
              'success',
              this.$t('notes.createSuccessMsg', {
                text: this.$t('common.annotation'),
              }),
            );
          }
          this.isLoading = false;
        },
        error => {
          this.$handleError(error);
        },
      );
    },
    updateAnnotation(annotation, cb, hideMsg) {
      this.isLoading = true;
      this.$api.updateAnnotationsFact(annotation).then(
        response => {
          if (!this.$handleResponse(response)) return;
          this.annotation = response.data.payload;
          const index = findIndex(
            this.annotations,
            item => item.id === this.annotation.id,
          );
          const oldAnno = find(
            this.annotations,
            item => item.id === this.annotation.id,
          );
          this.annotations.splice(index, 1, this.annotation);
          if (cb) {
            cb(this.annotation, oldAnno);
          }
          this.saveResponse();
          if (!hideMsg) {
            this.$toastr(
              'success',
              this.$t('notes.updateSuccessMsg', {
                text: this.$t('common.annotation'),
              }),
            );
          }
          this.isLoading = false;
        },
        error => {
          this.$handleError(error);
        },
      );
    },
    deleteDrawing(anno, cb) {
      this.$deleteConfirmation(
        this.$t('notes.deleteWarningMsg', {
          text: this.$t('common.annotation'),
        }),
        () => {
          this.$api.deleteAnnotationsFact(anno.id).then(
            response => {
              if (!this.$handleResponse(response)) return;
              const index = findIndex(
                this.annotations,
                item => item.id === anno.id,
              );
              const pathIndex = findIndex(
                this.paths,
                item => item.annotation && item.annotation.id === anno.id,
              );
              if (pathIndex >= 0) {
                const path = this.paths[pathIndex];
                const subPaths = filter(
                  this.paths,
                  item => item.annotation && item.annotation.id === anno.id,
                );
                if (subPaths && subPaths.length) {
                  const lastPath = subPaths && subPaths.length
                    ? subPaths[subPaths.length - 1]
                    : {};
                  this.layer.removeChildren(path.index, lastPath.index + 1);
                  this.paths = cloneDeep(filter(this.paths, item => item.annotation.id !== anno.id));
                } else {
                  this.layer.removeChildren(path.index, path.index + 1);
                  this.paths.splice(pathIndex, 1);
                }
              }
              this.showSettings = false;
              this.showInfo = false;
              this.annotations.splice(index, 1);
              if (cb) {
                cb(anno);
              }
              this.$emit('annotation-deleted', anno);
              this.$toastr('success', 'Annotation deleted successfully');
            },
            error => {
              this.$handleError(error);
            },
          );
        },
      );
    },
    addDrawing(type, annoDisable, saveAnno) {
      this.modalTitle = 'Create New Annotation'
      if (this.showSettings) {
        this.cancelDrawing();
      }
      this.annotation = {
        id: 0,
        annotationType: type,
        star: false,
        zoom: this.zoom,
        paperZoom: '',
        rotation: this.rotation,
        title: '',
        description: '',
        index: null,
        source: this.annoSource,
        slideUUID: this.slideData.fuuid,
        slideId: this.slideData.id,
        points: [],
        position: null,
        path: {
          strokeColor: '#000000',
          strokeWidth: 2,
          fillColor: 'rgba(100, 100, 100, 0.001)',
          opacity: 1,
        },
      };
      this.annotation.zoom = this.viewer.viewport.getZoom(true);
      this.annotation.index = this.generateID(this.annotations);
      this.enableDrawing(true, type, annoDisable, saveAnno);
    },
    handleKey(event, isActive) {
      if (!isActive) return;
      const keyName = event.key;
      if (keyName === 'Alt') return;
      if (this.annotations.length > 0) {
        switch (keyName) {
          case 'ArrowDown':
            event.preventDefault();
            if (this.currentAnno < this.annotations.length - 1) {
              this.currentAnno += 1;
              this.showDrawing(this.annotations[this.currentAnno]);
            }
            break;
          case 'ArrowUp':
            event.preventDefault();
            if (this.currentAnno > 0) {
              this.currentAnno -= 1;
              this.showDrawing(this.annotations[this.currentAnno]);
            }
            break;
          case 'Down':
            event.preventDefault();
            if (this.currentAnno < this.annotations.length - 1) {
              this.currentAnno += 1;
              this.showDrawing(this.annotations[this.currentAnno]);
            }
            break;
          case 'Up':
            event.preventDefault();
            if (this.currentAnno > 0) {
              this.currentAnno -= 1;
              this.showDrawing(this.annotations[this.currentAnno]);
            }
            break;
          default:
            break;
        }
      }
    },
    initTool() {
      if (this.tool) {
        this.tool.remove();
        this.tool = null;
      }
      this.paperScope.activate();
      this.tool = new this.paperScope.Tool();
    },
    enableDrawing(flag, type, annoDisable, saveAnno) {
      if (flag) {
        this.$toastr(
          'info', 'You can start drawing on the slide',
        );
        this.showInfo = false;
        this.showSettings = false;
        this.isDrawing = true;
        this.viewer.setMouseNavEnabled(false);
        this.initTool();
        this.clearMouseEvents();
        this.annotation = {
          ...this.annotation,
          points: [],
        };
        if (type === ANNOTATION_LOOPED || type === ANNOTATION_FREEHAND) {
          this.tool.on('mousedown', this.onMouseDown);
          this.tool.on('mousedrag', this.onMouseDrag);
          this.tool.on('mouseup', evt => {
            this.onMouseUp(evt, annoDisable, () => {
              this.annotation = assign(this.annotation, {
                zoom: this.zoom,
                paperZoom: this.paperScope.view.zoom,
                rotation: this.rotation,
                position: this.getAnnotationCenter(this.annotation.points),
              });
              if (this.annotation.annotationType === ANNOTATION_LOOPED) {
                this.path.closed = true;
              }
              this.enableDrawing(false);
              if (
                this.annotation.annotationType === ANNOTATION_LOOPED
                || this.annotation.annotationType === ANNOTATION_FREEHAND
              ) {
                this.path.smooth();
              }
            });
          });
        } else if (type === ANNOTATION_LINE) {
          this.tool.on('mousedown', this.onLineMouseDown);
          this.tool.on('mousedrag', event => {
            if (this.currentpoint) this.path.removeSegment(1);
            this.path.add(event.point);
            this.currentpoint = event.point;
            this.path.smooth();
          });
          this.tool.on('mouseup', evt => {
            this.onMouseUp(evt, annoDisable, event => {
              const point = {
                id: 0,
                x: event.point.x,
                y: event.point.y,
              };
              point.order = this.annotation.points.length;
              this.annotation.points.push(point);
              if (this.currentpoint) this.path.removeSegment(1);
              this.path.add(event.point);
              this.path.smooth();
              this.annotation = assign(this.annotation, {
                zoom: this.zoom,
                distance: this.getLineDistance(),
                paperZoom: this.paperScope.view.zoom,
                rotation: this.rotation,
                position: this.getAnnotationCenter(this.annotation.points),
              });
            });
          });
        } else if (type === ANNOTATION_RECTANGLE) {
          this.tool.on('mousedown', this.onMouseDownForSquare);
          this.tool.on('mousedrag', this.onMouseDragForSquare);
          this.tool.on('mouseup', evt => {
            this.onMouseUp(
              evt,
              annoDisable,
              () => {
                this.annotation = assign(this.annotation, {
                  zoom: this.zoom,
                  area: this.getArea(),
                  paperZoom: this.paperScope.view.zoom,
                  rotation: this.rotation,
                  position: this.getAnnotationCenter(this.annotation.points),
                });
                if (saveAnno) this.saveDrawing(this.annotation);
              },
              saveAnno,
            );
          });
        } else if (type === ANNOTATION_CIRCLE) {
          this.tool.on('mousedown', this.onMouseDownForCircle);
          this.tool.on('mousedrag', this.onMouseDragForSquare);
          this.tool.on('mouseup', evt => {
            this.onMouseUp(evt, annoDisable, () => {
              this.annotation = assign(this.annotation, {
                zoom: this.zoom,
                area: this.getCircleArea(),
                paperZoom: this.paperScope.view.zoom,
                rotation: this.rotation,
                position: this.getAnnotationCenter(this.annotation.points),
              });
            });
          });
        }
      } else {
        this.viewer.setMouseNavEnabled(true);
        this.isDrawing = false;
      }
    },
    generateID(shapes) {
      if (shapes && shapes.length === 0) return 0;
      return max(map(shapes, 'index')) + 1;
    },
    undoDrawing() {
      this.enableDrawing(false);
      this.showSettings = false;
      this.layer.removeChildren(this.path.index, this.path.index + 1);
      this.paths.splice(
        findIndex(this.paths, item => item.id === this.path.id),
        1,
      );
      this.path = null;
      this.addDrawing(this.annotation.annotationType);
    },
    showDrawing(item) {
      if (this.$mq === 'sm') {
        this.showList = false;
      }
      this.currentAnno = findIndex(
        this.annotations,
        anno => anno.id === item.id,
      );
      this.currentAnnoId = item.id;
      this.showAll = false;
      this.resetDrawing();
      this.initTool();
      this.panViewer(item.position.x, item.position.y);
      this.paperScope.view.center = new this.paperScope.Point({
        x: item.position.x,
        y: item.position.y,
      });
      this.rotate(item.rotation);
      this.zoomViewer(item.zoom);
      if (!item.paperZoom) {
        item.paperZoom = this.canvas.width * item.zoom; // eslint-disable-line
        this.updateAnnotation(item, null, true);
      }
      this.drawOne(item);
      this.annotation = cloneDeep(item);
      this.initMouseMove();
    },
    editDrawing(item) {
      this.showDrawing(item);
      this.modalTitle='Edit Annotation';
      this.annotation = cloneDeep(item);
      this.path = this.paths[this.paths.length - 1];
      this.toggleSettings();
    },
    cancelDrawing() {
      this.showSettings = false;
      if (
        this.annotation
        && (!this.annotation.id || this.annotation.id === 0)
        && this.path
      ) {
        this.layer.removeChildren(this.path.index, this.path.index + 1);
        this.paths.splice(
          findIndex(this.paths, item => item.id === this.path.id),
          1,
        );
      }
      this.path = null;
      this.annotation = null;
      this.initMouseMove();
      this.enableDrawing(false);
    },
    drawOne(annotation) {
      this.checkedAnnotations.push(annotation.id);
      if (annotation.annotationType === ANNOTATION_RECTANGLE) {
        this.drawSquareAnnotation(annotation);
      } else if (annotation.annotationType === ANNOTATION_CIRCLE) {
        this.drawCircleAnnotation(annotation);
      } else {
        this.drawPathAnnotation(annotation);
      }
    },
    drawSquareAnnotation(annotation) {
      if (annotation.points.length < 2) {
        return;
      }
      const width = 0.001 * (1 / this.paperScope.view.zoom);
      const topLeft = annotation.points[0];
      const bottomRight = annotation.points[annotation.points.length - 1];
      const path = new this.paperScope.Path.Rectangle({
        rectangle: {
          topLeft: [topLeft.x, topLeft.y],
          bottomRight: [bottomRight.x + width, bottomRight.y + width],
        },
        strokeColor: this.convertHex(
          annotation.path.strokeColor,
          annotation.path.opacity,
        ),
        fillColor: this.convertHex(
          annotation.path.fillColor,
          annotation.path.opacity,
        ),
        strokeWidth:
          annotation.path.strokeWidth
          * (1
            / (annotation.paperZoom
              ? annotation.paperZoom
              : this.paperScope.view.zoom)),
      });
      path.annotation = cloneDeep(annotation);
      this.layer.addChild(path);
      this.paths.push(path);
      this.paperScope.view.draw();
    },
    drawCircleAnnotation(annotation) {
      if (annotation.points.length < 2) {
        return;
      }
      const radius = 0.001 * (1 / this.paperScope.view.zoom);
      const topLeft = annotation.points[0];
      const bottomRight = annotation.points[annotation.points.length - 1];
      this.path = new this.paperScope.Path.Circle({
        center: topLeft,
        radius,
        strokeColor: this.convertHex(
          annotation.path.strokeColor,
          annotation.path.opacity,
        ),
        fillColor: this.convertHex(
          annotation.path.fillColor,
          annotation.path.opacity,
        ),
        strokeWidth:
          annotation.path.strokeWidth
          * (1
            / (annotation.paperZoom
              ? annotation.paperZoom
              : this.paperScope.view.zoom)),
      });
      this.path.annotation = cloneDeep(annotation);
      const bounds = new this.paperScope.Rectangle({
        topLeft: [topLeft.x, topLeft.y],
        bottomRight: [bottomRight.x + radius, bottomRight.y + radius],
      });
      this.path.setBounds(bounds);
      this.layer.addChild(this.path);
      this.paths.push(this.path);
      this.paperScope.view.draw();
    },

    drawPathAnnotation(annotation) {
      const path = new this.paperScope.Path();
      path.strokeColor = this.convertHex(
        annotation.path.strokeColor,
        annotation.path.opacity,
      );
      path.fillColor = this.convertHex(
        annotation.path.fillColor,
        annotation.path.opacity,
      ); 
      path.strokeWidth = annotation.path.strokeWidth
        * (1
          / (annotation.paperZoom
            ? annotation.paperZoom
            : this.paperScope.view.zoom));
      forEach(annotation.points, point => {
        path.add(new this.paperScope.Point(point.x, point.y));
      });
      if (annotation.annotationType === ANNOTATION_LOOPED) {
        path.closed = true;
      }
      path.smooth();
      path.annotation = cloneDeep(annotation);
      this.layer.addChild(path);
      this.paths.push(path);
      this.paperScope.view.draw();
    },
    drawAll() {
      if(this.annotations && this.annotations.length > 0){
        this.resetDrawing();
        this.showAll = true;
        this.checkedAnnotations = [];
        let curentSlideannotation = this.annotations.filter(el=>el.slideId===this.slideData.id);

        forEach(curentSlideannotation, item => {
          const anno = cloneDeep(item);
          anno.rotation = this.rotation;
          this.drawOne(anno);
        });
        this.initMouseMove();
      }
    },
    onLineMouseDown(event) {
      this.path = new this.paperScope.Path();
      const point = {
        id: 0,
        x: event.point.x,
        y: event.point.y,
      };
      point.order = this.annotation.points.length;
      this.annotation.points.push(point);
      this.tool.minDistance = 5 * (1 / this.paperScope.view.zoom);
      this.path.strokeColor = this.convertHex(
        this.annotation.path.strokeColor,
        this.annotation.path.opacity,
      ); 
      this.path.fillColor = this.convertHex(
        this.annotation.path.fillColor,
        this.annotation.path.opacity,
      ); 
      this.path.strokeWidth = this.annotation.path.strokeWidth * (1 / this.paperScope.view.zoom);
      this.path.add(event.point);
    },
    getCoordinatesForCalculation(points) {
      const [a, b] = points || this.annotation.points;
      const point1 = new OpenSeadragon.Point(a.x, a.y);
      const point2 = new OpenSeadragon.Point(b.x, b.y);
      let image1;
      let image2;
      if (this.viewer.world.getItemAt(0) > 1) {
        image1 = this.viewer.world
          .getItemAt(0)
          .viewportToImageCoordinates(point1.x, point1.y);
        image2 = this.viewer.world
          .getItemAt(0)
          .viewportToImageCoordinates(point2.x, point2.y);
      } else {
        image1 = this.viewer.viewport.viewportToImageCoordinates(
          point1.x,
          point1.y,
        );
        image2 = this.viewer.viewport.viewportToImageCoordinates(
          point2.x,
          point2.y,
        );
      }

      const x1 = image1.x;
      const x2 = image2.x;
      const y1 = image1.y;
      const y2 = image2.y;
      return {
        x1,
        x2,
        y1,
        y2,
      };
    },
    getLineDistance(points) {
      if (!this.slideData.mdMpp) return 0;
      const {
        x1, x2, y1, y2,
      } = this.getCoordinatesForCalculation(points);
      const xs = (x2 - x1) * (x2 - x1);
      const ys = (y2 - y1) * (y2 - y1);
      const pl = xs + ys;
      const sqt = Math.sqrt(pl);
      const ln = (sqt * this.mpp) / 1000;
      return ln;
    },
    getArea(points) {
      if (!this.slideData.mdMpp) return 0;
      const {
        x1, x2, y1, y2,
      } = this.getCoordinatesForCalculation(points);
      const xs = x2 - x1;
      const ys = y2 - y1;
      const pl = xs * ys;
      const ln = (pl * this.mpp) / 1000;
      return ln;
    },
    getCircleArea(points) {
      if (!this.slideData.mdMpp) return 0;
      const {
        x1, x2, y1, y2,
      } = this.getCoordinatesForCalculation(points);
      const pw = (x2 - x1) / 2;
      const ph = (y2 - y1) / 2;
      return (Math.PI * pw * ph * this.mpp) / 1000;
    },
    updatePathSize(data) {
      const point1 = data.points[0];
      const point2 = data.points[data.points.length - 1];
      if (data.radius1) {
        this.newPoint = {
          ...point2,
          x:
            point2.x + ((data.radius1 || 0)
              * (1
                / (data.paperZoom ? data.paperZoom : this.paperScope.view.zoom))),
          y:
            point2.y + ((data.radius1 || 0)
              * (1
                / (data.paperZoom ? data.paperZoom : this.paperScope.view.zoom))),
        };
      } else {
        this.newPoint = {
          ...point2,
          x:
            point2.x + ((data.width1 || 0)
              * (1
                / (data.paperZoom ? data.paperZoom : this.paperScope.view.zoom))),
          y:
            point2.y + ((data.height1 || 0)
              * (1
                / (data.paperZoom ? data.paperZoom : this.paperScope.view.zoom))),
        };
      }
      const bounds = new this.paperScope.Rectangle({
        topLeft: [point1.x, point1.y],
        bottomRight: [this.newPoint.x, this.newPoint.y],
      });
      this.path.setBounds(bounds);
      this.annotation.area = data.annotationType === ANNOTATION_CIRCLE
        ? this.getCircleArea([point1, this.newPoint])
        : this.getArea([point1, this.newPoint]);
    },
    onMouseDown(event) {
      if (!event.point) return;
      const point = {
        id: 0,
        x: event.point.x,
        y: event.point.y,
      };
      point.order = this.annotation.points.length;
      this.annotation.points.push(point);
      this.path = new this.paperScope.Path();
      this.tool.minDistance = 5 * (1 / this.paperScope.view.zoom);
      this.path.strokeColor = this.convertHex(
        this.annotation.path.strokeColor,
        this.annotation.path.opacity,
      ); 
      this.path.fillColor = this.convertHex(
        this.annotation.path.fillColor,
        this.annotation.path.opacity,
      ); 
      this.path.strokeWidth = this.annotation.path.strokeWidth * (1 / this.paperScope.view.zoom);
      this.path.add(event.point);
    },
    onMouseDrag(event) {
      const point = {
        id: 0,
        x: event.point.x,
        y: event.point.y,
      };
      point.order = this.annotation.points.length;
      this.annotation.points.push(point);
      this.path.add(event.point);
    },
    onMouseUp(evt, annoDisable, cb) {
      if (cb) cb(evt);
      this.enableDrawing(false);
      this.clearMouseEvents();
      this.paths.push(this.path);
      const index = this.paths.length - 1;
      this.layer.addChild(this.paths[index]);
      this.paperScope.view.draw();
      if (!this.enableAnnotations || annoDisable) {
        return;
      }
      this.toggleSettings();
    },
    // for square
    onMouseDownForSquare(event) {
      const width = 0.001 * (1 / this.paperScope.view.zoom);
      const point = {
        id: 0,
        x: event.point.x,
        y: event.point.y,
      };
      point.order = this.annotation.points.length;
      this.annotation.points.push(point);
      this.path = new this.paperScope.Path.Rectangle(
        [point.x, point.y],
        new this.paperScope.Size([width, width]),
      );
      this.path.strokeColor = this.convertHex(
        this.annotation.path.strokeColor,
        this.annotation.path.opacity,
      );
      this.path.fillColor = this.convertHex(
        this.annotation.path.fillColor,
        this.annotation.path.opacity,
      );
      this.path.strokeWidth = this.annotation.path.strokeWidth * (1 / this.paperScope.view.zoom);
    },
    onMouseDragForSquare(event) {
      if (this.annotation.points.length < 1 || !this.path) {
        return;
      }
      const width = 0.001 * (1 / this.paperScope.view.zoom);
      const point1 = this.annotation.points[0];
      const bounds = new this.paperScope.Rectangle({
        topLeft: [point1.x, point1.y],
        bottomRight: [event.point.x + width, event.point.y + width],
      });
      const point = {
        id: 0,
        x: event.point.x,
        y: event.point.y,
      };
      this.annotation.points = [];
      this.annotation.points.push(point1);
      point.order = this.annotation.points.length;
      this.annotation.points.push(point);
      this.path.setBounds(bounds);
    },
    // end
    // for circle
    onMouseDownForCircle(event) {
      const radius = 0.001 * (1 / this.paperScope.view.zoom);
      const point = {
        id: 0,
        x: event.point.x,
        y: event.point.y,
      };
      point.order = this.annotation.points.length;
      this.annotation.points.push(point);
      this.path = new this.paperScope.Path.Circle({
        center: event.point,
        radius,
        strokeColor: this.convertHex(
          this.annotation.path.strokeColor,
          this.annotation.path.opacity,
        ),
        fillColor: this.convertHex(
          this.annotation.path.fillColor,
          this.annotation.path.opacity,
        ),
        strokeWidth:
          this.annotation.path.strokeWidth * (1 / this.paperScope.view.zoom),
      });
    },
    // end
    clearMouseEvents() {
      this.tool.off('mousedown', null);
      this.tool.off('mousedrag', null);
      this.tool.off('mouseup', null);
    },
    toggleSettings() {
      this.showSettings = true;
      this.settingsPosition = this.getSettingsPosition();
    },
    updatePath(data) {
      this.annotation = data;
      this.path.strokeColor = this.convertHex(
        data.path.strokeColor,
        data.path.opacity,
      );
      this.path.fillColor = this.convertHex(
        data.path.fillColor,
        data.path.opacity,
      );
      this.path.strokeWidth = data.path.strokeWidth
        * (1
          / (this.annotation.paperZoom
            ? this.annotation.paperZoom
            : this.paperScope.view.zoom));
    },
    convertHex(hex, opacity) {
      let result;
      if (hex.indexOf('rgb') !== -1) {
        result = hex;
      } else {
        const str = hex.replace('#', '');
        const r = parseInt(str.substring(0, 2), 16);
        const g = parseInt(str.substring(2, 4), 16);
        const b = parseInt(str.substring(4, 6), 16);
        result = `rgba(${r},${g},${b},${opacity})`;
      }
      return result;
    },
    getSettingsPosition() {
      if (!this.showSettings && !this.showInfo) return null;
      const maxX = max(map(this.annotation.points, 'x'));
      const minY = min(map(this.annotation.points, 'y'));
      const dimens = this.viewer.viewport.pixelFromPoint(new OpenSeadragon.Point(maxX, minY));
      return {
        top: `${Number(dimens.y)}px`,
        left: `${Number(dimens.x)}px`,
      };
    },
    getAnnotationCenter(points) {
      const maxY = max(map(points, 'y'));
      const minY = min(map(points, 'y'));
      const maxX = max(map(points, 'x'));
      const minX = min(map(points, 'x'));
      return {
        x: (minX + maxX) / 2,
        y: (minY + maxY) / 2,
      };
    },
    initMouseMove() {
      this.initTool();
      this.tool.onMouseMove = event => {
        const hitOptions = {
          segments: true,
          stroke: true,
          fill: true,
          tolerance: 5,
        };
        const [x, y] = [event.point.x, event.point.y];
        window.$(`#${this.paperElement}`)[0].innerHTML = `Coordinates: ('${x}', '${y}')`;
        forEach(this.paths, item => {
          const hitResult = item.hitTest(event.point, hitOptions); // isPointInPath
          if (hitResult) {
            if (event.item) {
              if (this.showSettings) return;
              this.annotation = cloneDeep(event.item.annotation);
              if (this.annotation && this.annotation.id) {
                this.showInfo = true;
                this.showSettings = false;
                this.settingsPosition = this.getSettingsPosition();
              }
            }
          }
        });
      };
    },
    remomveAll(items){
      items.forEach(item=>{
        if (this.checkedAnnotations.includes(item.id)) {
          const index = findIndex(
            this.checkedAnnotations,
            id => id === item.id,
          );
          this.checkedAnnotations.splice(index, 1);
          const pathIndex = findIndex(
            this.paths,
            path => path.annotation.id === item.id,
          );
          this.paths.splice(pathIndex, 1);
          this.layer.removeChildren();
          this.layer.children = null;
          this.showInfo = false;
          forEach(this.paths, pat => {
            this.layer.addChild(pat);
          });
        }
      })
      
    },
    annotaionChecked(item) {
      if (this.checkedAnnotations.includes(item.id)) {
        const index = findIndex(
          this.checkedAnnotations,
          id => id === item.id,
        );
        this.checkedAnnotations.splice(index, 1);
        const pathIndex = findIndex(
          this.paths,
          path => path.annotation.id === item.id,
        );
        this.paths.splice(pathIndex, 1);
        this.layer.removeChildren();
        this.layer.children = null;
        this.showInfo = false;
        forEach(this.paths, pat => {
          this.layer.addChild(pat);
        });
      } else {
        this.drawOne(item);
      }
    },
  },
};
