<template>
    <div v-if="slideData">
        <b-modal v-model="$modalData.show" v-on:hide="resetSlide" 
            hide-footer 
            no-close-on-backdrop 
            no-close-on-esc centered 
            :title="$modalData.title" size="xl" :hide-header-close="$modalData.hideCloseIcon" 
            :modal-class="`select-slide-modal ${$modalData.class} upload-slide`"
            body-class="pb-1"
        >
            <div class="modal-class upload-slide-class">
                <div class="align-items-center d-flex flex-column justify-content-center position-relative">
                    <div class="w-100" :class="{ 'fetch-class': !isLoadAllMetaData }">
                        <div class="pb-2 row px-xl-3">
                            <div class="col-12 col-xl-2">
                                <label>Case Number</label>
                            </div>
                            <b-col class="col-12 col-xl-10 px-30">
                                <b-row>
                                    <div class="col-4 px-0">
                                        <b-form-input :placeholder="$t('common.caseNumber')" :aria-label="$t('common.caseNumber')" :aria-labelledby="$t('common.caseNumber')" 
                                            v-if="caseDetail.systemCaseNumber == null"
                                            type="text" :name="`CaseNumber`" 
                                            v-model="caseDetail.caseNumber" size="sm" 
                                            v-validate="'required'" :state="errors.has(`CaseNumber`)?false:null"
                                        ></b-form-input>
                                        <b-form-input :placeholder="$t('common.caseNumber')" :aria-label="$t('common.caseNumber')" :aria-labelledby="$t('common.caseNumber')" 
                                            v-if="caseDetail.systemCaseNumber != null"
                                            type="text" :name="`CaseNumber`" 
                                            v-model="caseDetail.systemCaseNumber" size="sm" 
                                            v-validate="'required'" :state="errors.has(`CaseNumber`)?false:null"
                                            disabled
                                        ></b-form-input>
                                    </div>
                                    <div class="col-auto">
                                        <small style="opacity: 0.8;" class="text-primary">
                                            Case number will be automatically changed after moving to Editorial Board box*
                                        </small>
                                    </div>
                                </b-row>
                            </b-col>
                        </div>
                        <div class="pb-2 row px-xl-3">
                            <div class="col-12 col-xl-2">
                                <label>Diagnosis</label>
                            </div>
                            <b-col class="col-12 col-xl-10 px-30">
                                <b-row>
                                    <div class="col-4 px-0">
                                        <p-dropdown :aria-label="$t('common.tumorSite')" :aria-labelledby="$t('common.tumorSite')" :placeholder="$t('common.tumorSite')" 
                                            :name="`TumorSite`" optionLabel="name" 
                                            @change="getAllTumorTypes(caseDetail.tumorSite.id)" 
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-validate="'required'" :class="(errors.has(`TumorSite`))?'invalid':''" 
                                            v-model="caseDetail.tumorSite" 
                                            :options="sortedItemsAsc(allMetaData.tumorSites, 'name')"
                                        ></p-dropdown>
                                    </div>
                                    <div class="upload-slide col-4 pr-0 pl-2 cus-placeholder" lang="en">
                                        <el-select :aria-label="$t('common.tumorType')" :aria-labelledby="$t('common.tumorType')" 
                                            :placeholder="$t('common.tumorType')" :title="`Select Tumor Type`"
                                            :name="`TumorType`" optionLabel="name"
                                            class="w-100 h-100"
                                            :required="true" @change="getAllTumorSubTypes(false)" 
                                                v-model="caseDetail.tumorType">
                                                    <el-option-group
                                                        v-for="tumorGroup in tumorTypes"
                                                        :key="tumorGroup.label"
                                                        :label="tumorGroup.label"
                                                    >
                                                    <el-option
                                                        v-for="item in tumorGroup.items"
                                                        :key="item.id"
                                                        :label="item.value"
                                                        :value="item"
                                                    />
                                                    </el-option-group>
                                        </el-select>
                                    </div>
                                    <div class="col-4 pr-0 pl-2">
                                        <p-dropdown :aria-label="$t('common.tumorSubType')" :aria-labelledby="$t('common.tumorSubType')" :placeholder="$t('common.tumorSubType')" 
                                            :name="`TumorSubType`" optionLabel="name" :title="`Select Tumor Subtype`"
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-validate="'required'" :class="(errors.has(`TumorSubType`) && isCheckValidation)?'invalid':''" 
                                            v-model="caseDetail.tumorSubType" 
                                            :options="sortedItemsAsc(tumorSubTypes,'name')"
                                        ></p-dropdown>
                                    </div>
                                </b-row>
                            </b-col>
                        </div>
                        <div class="pb-2 row px-xl-3">
                            <div class="col-12 col-xl-2">
                                <label>Case Notes</label>
                            </div>
                            <b-col class="col-12 col-xl-10 px-30">
                                <b-row>
                                    <div class="col-12 px-0">
                                        <b-form-input :placeholder="'Case Notes, max 300 characters'" :aria-label="$t('common.caseNotes')" :aria-labelledby="$t('common.caseNotes')" 
                                            type="text" :name="`CaseNotes`" 
                                            v-model="caseDetail.caseNotes" size="sm"
                                            :state="errors.has(`CaseNotes`)?false:null"
                                            maxlength="300"
                                        ></b-form-input>
                                        <div class="d-flex justify-content-end">
                                            <small class="text-muted">{{ caseDetail.caseNotes ? caseDetail.caseNotes.length : 0 }}/300 characters</small>
                                        </div>
                                    </div>
                                </b-row>
                            </b-col>
                        </div>

                        <div class="pb-2 row px-xl-3">
                            <div class="col-12 col-xl-2">
                                <label>Diagnostic Features</label>
                            </div>
                            <b-col class="col-12 col-xl-10">
                                <b-row>
                                    <div class="col-8">
                                        <wtr-cascade-selection
                                            :placeholder="'Select Feature'"
                                            :options="allMetaData.diagnosticFeatures" optionLabel="name" compairKey="id" levelKey="parentId"
                                            addOptionFlag="freeTextOption" removeOptionFlag="onFlyEntry" onlyNumericFlag="onlyNumeric"
                                            :selectedFields="caseDetail.diagnosticFeatures"
                                            :selectionFirstLevel="'multiple'"
                                            :selectionSecondLevel="'single'"
                                            :isCheckValidation = isCheckValidation
                                            :addFieldTumorSiteId = "caseDetail.tumorSite ? caseDetail.tumorSite.id : null"
                                            :createdChildOptions = "createdChildOptionsImmunoChemi"
                                            :hideFlag=false
                                            :dropdownName="'DiagnosticFeatures'"
                                            @selected-items="(payload)=>{caseDetail.diagnosticFeatures = payload}"
                                            @is-validation-error="payload=>{slidesValidation.diagnosticFeatures[0]=payload}"
                                        ></wtr-cascade-selection>
                                    </div>
                                </b-row>
                            </b-col>
                        </div>

                        <div class="pb-2 row px-xl-3">
                            <div class="col-12 col-xl-2">
                                <label>Patient Information</label>
                            </div>
                            <b-col class="col-12 col-xl-10 px-30">
                                <b-row>
                                    <div class="col-2 px-0">
                                        <p-dropdown :aria-label="$t('common.sex')" :aria-labelledby="$t('common.sex')" :placeholder="$t('common.sex')" 
                                            :name="'Sex'" optionLabel="value" optionValue="value" :title="`Select Sex`" 
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-validate="'required'" :class="(errors.has('Sex'))?'invalid':''" 
                                            v-model="caseDetail.sex" 
                                            :options="sortedItemsAsc(gender, 'value')"
                                        ></p-dropdown>
                                    </div>
                                    <div class="col-2 pr-0 pl-2">
                                        <p-dropdown 
                                            :aria-label="$t('common.age')" :aria-labelledby="$t('common.age')" :placeholder="$t('common.age')" 
                                            :name="`Age`" optionLabel="name" optionValue="value" :title="`Select Age`" 
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-validate="'required'" :class="(errors.has(`Age`))?'invalid':''" 
                                            v-model="caseDetail.age" :options="ages"
                                        ></p-dropdown>
                                    </div>
                                    <div class="col-4 pr-0 pl-2">
                                        <p-dropdown 
                                            :aria-label="$t('common.geographicRegion')" :aria-labelledby="$t('common.geographicRegion')" :placeholder="$t('common.geographicRegion')" 
                                            :name="`GeographicRegion`" optionLabel="name" 
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            @change="getCountryByRegion($event.value.id)"  
                                            v-validate="'required'" :class="(errors.has(`GeographicRegion`))?'invalid':''" 
                                            v-model="caseDetail.geographicRegion" 
                                            :options="sortedItemsAsc(allMetaData.geographicRegions, 'name')" :disabled="userRole === 'GUEST' || userRole === 'EDITOR'
                                            || userRole === 'CHIEF EDITOR'"
                                        ></p-dropdown>
                                    </div>
                                    <div class="col-4 pr-0 pl-2">
                                        <p-dropdown :aria-label="$t('common.country')" :aria-labelledby="$t('common.country')" :placeholder="$t('common.country')" 
                                            :name="`Country`" optionLabel="country" optionValue="country" :title="`Select Country`" 
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-validate="'required'" :class="(errors.has(`Country`) && isCheckValidation)?'invalid':''" 
                                            v-model="caseDetail.country" 
                                            :options="sortedItemsAsc(countries, 'country')"
                                        ></p-dropdown>
                                    </div>
                                </b-row>
                            </b-col>
                        </div>
                        <div class="pb-2 row px-xl-3">
                            <div class="col-12 col-xl-2"></div>
                            <b-col class="col-12 col-xl-10 px-30">
                                <b-row>
                                    <div class="col-4 px-0">
                                         <wtr-selection-dropdown
                                            :placeholder="'Select State'"
                                            :options="allMetaData.state" optionLabel="name" compairKey="id"
                                            addOptionFlag="freeTextOption" removeOptionFlag="onFlyEntry"
                                            :selectedFields="caseDetail.states"
                                            :selection="'single'"
                                            :isCheckValidation = isCheckValidation
                                            :addFieldTumorSiteId = "caseDetail.tumorSite ? caseDetail.tumorSite.id : null"
                                            @selected-items="payload=>{caseDetail.states = payload}"
                                            @is-validation-error="payload=>{slidesValidation.states[0]=payload}"
                                            :dropdownClass = "'w-100'"
                                            :dropdownName="'state'"
                                        ></wtr-selection-dropdown>
                                    </div>
                                    <div class="col-4 pr-0 pl-2">
                                         <wtr-cascade-selection
                                            :placeholder="'Select Environmental Setting'"
                                            :options="allMetaData.environmentalSettings" optionLabel="name" compairKey="id" levelKey="parentId"
                                            addOptionFlag="freeTextOption" removeOptionFlag="onFlyEntry"
                                            :selectedFields="caseDetail.environmentalSettings"
                                            :selectionFirstLevel="'single'"
                                            :selectionSecondLevel="'multiple'"
                                            :isCheckValidation = isCheckValidation
                                            :addFieldTumorSiteId = "caseDetail.tumorSite ? caseDetail.tumorSite.id : null"
                                            @selected-items="(payload)=>{caseDetail.environmentalSettings = payload;}"
                                            @is-validation-error="payload=>{slidesValidation.environmentalSettings[0]=payload}"
                                            :dropdownName="'environmentalSettings'"
                                        ></wtr-cascade-selection>
                                    </div>
                                    <div class="col-4 pr-0 pl-2">
                                        <wtr-cascade-selection
                                            :placeholder="'Select Familial Disease'"
                                            :options="allMetaData.familialDiseases" optionLabel="name" compairKey="id" levelKey="parentId"
                                            addOptionFlag="freeTextOption" removeOptionFlag="onFlyEntry"
                                            :selectedFields="caseDetail.familialDiseases"
                                            :selectionFirstLevel="'single'"
                                            :selectionSecondLevel="'single'"
                                            :isCheckValidation = isCheckValidation
                                            :addFieldTumorSiteId = "caseDetail.tumorSite ? caseDetail.tumorSite.id : null"
                                            @selected-items="(payload)=>{caseDetail.familialDiseases = payload;}"
                                            @is-validation-error="payload=>{slidesValidation.familialDiseases[0]=payload}"
                                            :dropdownName="'familialDiseases'"
                                        ></wtr-cascade-selection>
                                    </div>
                                </b-row>
                            </b-col>
                        </div>
                        <div class="pb-2 row px-xl-3">
                            <div class="col-12 col-xl-2">
                                <label>Case Details</label>
                            </div>
                            <b-col class="col-12 col-xl-10 px-30">
                                <b-row>
                                    <div class="col-4 px-0">
                                         <wtr-selection-dropdown
                                            :placeholder="'Select Tumor Size'"
                                            :options="allMetaData.tumorSizes" optionLabel="name" compairKey="id"
                                            addOptionFlag="freeTextOption" removeOptionFlag="onFlyEntry" onlyNumericFlag="onlyNumeric" 
                                            :selectedFields="caseDetail.tumorSizes"
                                            :selection="'single'"
                                            :addFieldTumorSiteId = "caseDetail.tumorSite ? caseDetail.tumorSite.id : null"
                                            @selected-items="payload=>{caseDetail.tumorSizes = payload}"
                                            :dropdownClass = "'w-100'"
                                            :dropdownName="'tumorSize'"
                                            :isCheckValidation = isCheckValidation
                                            @is-validation-error="payload=>{slidesValidation.tumorSizes[0]=payload}"
                                        ></wtr-selection-dropdown>
                                    </div>
                                    <div class="col-2 pr-0 pl-2">
                                        <p-dropdown :aria-label="$t('common.stage')" :aria-labelledby="$t('common.stage')" :placeholder="$t('common.stage')" 
                                            :name="`Stage`" optionLabel="name" :title="`Select Stage`"
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-model="caseDetail.stage" 
                                            v-validate="'required'" :class="(errors.has(`Stage`))?'invalid':''" 
                                            :options="sortedItemsAsc(allMetaData.stages, 'name')"
                                        ></p-dropdown>
                                    </div>
                                    <div class="col-2 pr-0 pl-2">
                                        <p-dropdown :aria-label="$t('common.tCategory')" :aria-labelledby="$t('common.tCategory')" :placeholder="$t('common.tCategory')" 
                                            :name="`TCategory`" optionLabel="name" :title="`Select T Category`"
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-model="caseDetail.tCategory" 
                                            v-validate="'required'" :class="(errors.has(`TCategory`))?'invalid':''" 
                                            :options="sortedItemsAsc(allMetaData.tCategories, 'name')"
                                        ></p-dropdown>
                                    </div>
                                    <div class="col-2 pr-0 pl-2">
                                        <p-dropdown :aria-label="$t('common.nCategory')" :aria-labelledby="$t('common.nCategory')" :placeholder="$t('common.nCategory')" 
                                            :name="`NCategory`" optionLabel="name" :title="`Select N Category`" 
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-model="caseDetail.nCategory" 
                                            v-validate="'required'" :class="(errors.has(`NCategory`))?'invalid':''" 
                                            :options="sortedItemsAsc(allMetaData.nCategories, 'name')"
                                        ></p-dropdown>
                                    </div>
                                    <div class="col-2 pr-0 pl-2">
                                        <p-dropdown :aria-label="$t('common.mCategory')" :aria-labelledby="$t('common.mCategory')" :placeholder="$t('common.mCategory')" 
                                            :name="`MCategory`" optionLabel="name" :title="`Select M Category`"
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-model="caseDetail.mCategory" 
                                            v-validate="'required'" :class="(errors.has(`MCategory`))?'invalid':''" 
                                            :options="sortedItemsAsc(allMetaData.mCategories, 'name')"
                                        ></p-dropdown>
                                    </div>
                                </b-row>
                            </b-col>
                        </div>
                        <div class="pb-2 row px-xl-3">
                            <div class="col-12 col-xl-2"></div>
                            <b-col class="col-12 col-xl-10 px-30">
                                <b-row>
                                    <div class="col-4 px-0">
                                        <p-dropdown 
                                            :aria-label="$t('common.cytology')" :aria-labelledby="$t('common.cytology')" :placeholder="$t('common.cytology')" 
                                            :name="`Cytology`" optionLabel="name" :title="`Select Cytology`"  
                                            class="p-dropdown-custom form-control form-control-sm" 
                                            v-model="caseDetail.cytology" 
                                            v-validate="'required'" :class="(errors.has(`Cytology`))?'invalid':''" 
                                            :options="sortedItemsCytology(allMetaData.cytologies, 'name')"
                                        ></p-dropdown>
                                    </div>
                                    <div class="col-4 pr-0 pl-2">
                                        <wtr-cascade-selection
                                            :placeholder="'Select Immuno/histochemistry'"
                                            :options="allMetaData.immunoHistoChemistries" optionLabel="name" compairKey="id" levelKey="parentId"
                                            addOptionFlag="freeTextOption" removeOptionFlag="onFlyEntry" onlyNumericFlag="onlyNumeric"
                                            :selectedFields="caseDetail.immunoHistoChemistries"
                                            :selectionFirstLevel="'multiple'"
                                            :selectionSecondLevel="'single'"
                                            :isCheckValidation = isCheckValidation
                                            :addFieldTumorSiteId = "caseDetail.tumorSite ? caseDetail.tumorSite.id : null"
                                            :createdChildOptions = "createdChildOptionsImmunoChemi"
                                            :hideFlag=false
                                            :dropdownName="'Immunohisto'"
                                            @selected-items="(payload)=>{caseDetail.immunoHistoChemistries = payload;}"
                                            @is-validation-error="payload=>{slidesValidation.immunoHistoChemistries[0]=payload}"
                                        ></wtr-cascade-selection>
                                    </div>
                                    <div class="col-4 pr-0 pl-2">
                                        <wtr-cascade-selection
                                            :placeholder="'Select Genetic'"
                                            :options="allMetaData.genetic" optionLabel="name" compairKey="id" levelKey="parentId"
                                            addOptionFlag="freeTextOption" removeOptionFlag="onFlyEntry" 
                                            :selectedFields="caseDetail.genetics"
                                            :selectionFirstLevel="'multiple'"
                                            :selectionSecondLevel="'single'"
                                            :isCheckValidation = isCheckValidation
                                            :isNotCheckChildValidation = true
                                            :isFreetextChild = false
                                            :addFieldTumorSiteId = "caseDetail.tumorSite ? caseDetail.tumorSite.id : null"              
                                            @selected-items="(payload)=>{caseDetail.genetics = payload;}"
                                            @is-validation-error="payload=>{slidesValidation.genetics[0]=payload}"
                                            :dropdownName="'genetic'"
                                        ></wtr-cascade-selection>                                        
                                    </div>
                                </b-row>
                            </b-col>
                        </div>
                        <div class="pb-3 row px-xl-3">
                            <div class="col-12 col-xl-2">
                                <label>{{$t('common.specificCohort')}}</label>
                            </div>
                            <b-col class="col-12 col-xl-10 px-30">
                                <b-row>
                                    <div class="col-4 px-0">
                                        <p-dropdown 
                                            :aria-label="$t('common.specificCohort')" :aria-labelledby="$t('common.specificCohort')" :placeholder="$t('common.specificCohort')" 
                                            :name="`SpecificCohort`" optionLabel="name" class="p-dropdown-custom form-control form-control-sm" 
                                            v-model="caseDetail.specificCohort" 
                                            v-validate="'required'" :class="(errors.has(`SpecificCohort`) && isCheckValidation)?'invalid':''" 
                                            :options="sortedItemsAsc(specificCohorts, 'name')"
                                        ></p-dropdown>
                                    </div>
                                </b-row>
                            </b-col>
                        </div>
                    </div>
                    <div 
                        v-if="!isLoadAllMetaData"
                        class="w-100 h-100 position-absolute align-items-center d-flex justify-content-center"
                    >
                        <wtr-loader 
                            class="text-primary pb-3"
                            loadingText=" Fetching MetaData..." addText 
                        ></wtr-loader>
                    </div>
                </div>
    
                <header class="modal-header modal-subheader" name="select-case-files">
                    <h5 class="modal-title">
                        Select case files
                    </h5>
                </header>
    
                <div class="pt-1 row px-xl-3 align-items-center">
                    <div class="col-12 col-xl-2 pl-0">
                        <input hidden type="file" multiple :disabled="!isLoadAllMetaData"
                            v-on:change="uploadFile" id="choose-file" aria-label="choose-file" aria-labelledby="choose-file" />
                        <label for="choose-file" class="btn btn-sm btn-primary mb-0 mr-2" title="Choose Files"
                            style="color: #fff !important;"
                            :class="!isLoadAllMetaData?'disabled':''"
                        >
                            {{ $t('slidebox.chooseFile') }}
                        </label>
                        <a class="btn btn-link text-info" id="fileSupport" :title="$t('notes.supportedFileFormats')">
                            <i class="fa fa-info-circle fs-18"></i>
                        </a>
                        <b-popover target="fileSupport" placement="right" :show.sync="showFileSupport" custom-class="">
                            <template #title>{{$t('notes.supportedFileFormats')}}</template>
                            <b-row v-for="(formatData, formatIndex) in supportedFormats" :key="formatIndex" class="pb-1">
                                <b-col class="col-4 fw-500 pr-0">
                                    {{ formatData.type }}
                                </b-col>
                                <b-col>
                                    <h6>
                                        <b-badge v-for="(format, typeIndex) in formatData.formats" :key="typeIndex" class="mr-1">
                                            {{ format }}
                                        </b-badge>
                                    </h6>
                                </b-col>
                            </b-row>
                            <div class="fs-12 border-top pt-5" style="line-space: 1.1;">
                                {{ $t('slidebox.toUpload') }}
                                <span class="fw-600">{{ $t('slidebox.mrxs') }}</span>
                                {{ $t('notes.filesPleaseUploadASingleZipFile') }}.
                            </div>
                        </b-popover>
                    </div>
                    <div class="col-12 col-xl-10 pr-0">
                        <b-row class="align-items-baseline">
                            <div class="col-8 col-md-6 col-lg-4 pr-0">
                                <wtr-storage-indicator 
                                    v-if="$showSubscription" :value="storageConsumed"   
                                ></wtr-storage-indicator>
                            </div>
                        </b-row>
                    </div>
                </div>
                <div class="d-flex align-items-center">                
                    <b-alert show variant="danger" class="py-5" v-if="excessStorage" 
                        v-html="$t('subscription.exceedStorageMsg', { size: formatFileSize(excessStorage)})"
                    >
                    </b-alert>
                </div>
    
                <div v-show="slides.length">
                    <label class="mb-0">Selected files:</label>
                    <div class="table-responsive">
                        <table class="table table-bordered" style="min-width: 900px;">
                            <thead>
                                <tr>
                                    <th class="text-center fw-600 w-12">Preview</th>
                                    <th class="text-center fw-600 w-12">File Order</th>
                                    <th class="fw-600">File Name</th>
                                    <th class="col-4 fw-600">Stain/Study</th>
                                    <th class="text-center fw-600 w-10">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(slide, dataIndex) in slides" :key="dataIndex">
                                    <td class="text-center position-relative">
                                        <div>
                                            <wtr-img
                                                imgClass="table-thumbnail"
                                                :resourceId="slide.metadata.resourceId"
                                                :containerName="slide.metadata.containerName"
                                                :src="slide.metadata.thumbnailPath"
                                                alt="Slide Image"
                                                aria-label="Slide Image"
                                                aria-labelledby="Slide Image"/>
                                        </div>  
                                        
                                        <div class="position-absolute" style="top:0;right:0;" v-if="isEditCase && showThumbnailUpload &&
                                        (userRole === 'TENANT ADMIN' || userRole === 'PROJECT ADMIN' || userRole === 'EDITOR' || userRole === 'CHAIRPERSON')">
                                            <wtr-image-upload
                                                :slide="slide"
                                                :altImage="$icons.NO_THUMBNAIL_FOUND"
                                                :validate="$isImage"
                                                :label="$t('administration.updateThumbnail')"
                                                :isThumbnailUpdate="true"
                                                @uploaded="thumbnailUploaded"
                                                @uploading="isImageUploading = $event"                                                
                                            ></wtr-image-upload>
                                       </div> 
                                    </td> 
                                    <td>
                                        <b-row class="justify-content-center">
                                            <p-dropdown
                                                class="p-dropdown-custom form-control form-control-sm w-40"
                                                v-model="slide.metadata.fileOrder" :options="fileOrderOptions"                                        
                                                optionLabel="label" placeholder="No." optionValue="value"
                                                :name="`fileOrder${dataIndex}`"  
                                                v-validate="'required'" 
                                                :class="(errors.has(`fileOrder${dataIndex}`) || validateFileOrderDropdowns(dataIndex))?'invalid':''" 
                                            ></p-dropdown>
                                        </b-row>
                                    </td>
                                    <td>
                                        <b-form-input placeholder="File Name" aria-label="File Name" aria-labelledby="File Name" 
                                            type="text" :name="`Name${dataIndex}`"
                                            @change="(payload)=>{slide.metadata.name = payload;}"
                                            v-model="slide.name" size="sm" 
                                            v-validate="'required'" :state="errors.has(`Name${dataIndex}`)?false:null"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <wtr-cascade-selection
                                            :placeholder="'Select Stain Study'"
                                            :options="allMetaData.stainStudy" optionLabel="name" compairKey="id" levelKey="parentId"
                                            addOptionFlag="freeTextOption" removeOptionFlag="onFlyEntry"
                                            :selectedFields="slide.metadata['stainStudies']"
                                            :selectionFirstLevel="'single'"
                                            :selectionSecondLevel="'single'"
                                            :isCheckValidation = isCheckValidation
                                            @selected-items="(payload)=>{slide.metadata['stainStudies'] = payload;}"
                                            :addFieldTumorSiteId = "caseDetail.tumorSite ? caseDetail.tumorSite.id : null"
                                            @is-validation-error="payload=>{slidesValidation.stainStudy[dataIndex]=payload}"
                                            :dropdownName="'stainStudy'"
                                        ></wtr-cascade-selection>
                                    </td>
                                    <td class="text-center">
                                        <a class="text-danger tab-delete-icon" title="Delete File" v-on:click="removeFile(dataIndex, slide)">
                                            <i class="fa fs-20 fa-trash-o"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
    
                <header class="modal-header modal-subheader" name="terms">
                    <h5 class="modal-title"> Terms of uploading files to WTR </h5>
                </header>
                <div class="py-2">
                    <div class="fs-12 text-justify text-primary">
                        This World Tumor Registry (WTR) platform should be used only for educational and exploratory purposes and not for direct diagnostic use. In uploading any files to this platform, you agree to comply with the <u><a class="text-primary" href="/terms" target="_blank">Terms of Use</a></u>. Further, you agree that you are responsible for the accuracy of information and the removal of patient identifiable information from files you upload in WTR. Although reasonable efforts have been made to remove label and any other patient identifiers from uploaded content, please review and confirm that you do not see any patient identifiable information displayed after uploading them. Patient information that should not be displayed include: name or initials (including names of relatives); all elements (except years) of dates relating to an individual (including birth date, admission date, discharge date, date of death, and exact age if over 89); address (all geographic subdivisions smaller than state, including street address, city, county, and zip/postal code); telephone or fax numbers; email addresses; web url; Internet Protocol (IP) address; social security number; medical record number; health insurance or health plan number; account number; pathology case number and report number; certificate or license number; any vehicle identifiers and serial numbers, including license plate numbers; medical device identifiers and serial numbers; finger or voice print or other biometric data; photographic image (not limited to images of the face); date of study; or any other characteristic that could uniquely identify the individual.
                    </div>
                    <div class="pt-2">
                        <b-form-checkbox name="error-tex" aria-label="error-tex" aria-labelledby="error-tex" size="sm" v-model="agree">
                            <div>
                                {{ $t('slidebox.slideUploadAgreeLabelMsg', { event: $t( this.slideData.id ? 'common.update_sm' : 'common.upload_sm', ), }) }}*
                            </div>
                        </b-form-checkbox>
                    </div>
                </div>
    
                <header class="modal-header modal-subheader" name="contributor-name">
                    <h5 class="modal-title"> Contributor and Editor </h5>
                </header>
                <div class="py-3 row px-xl-3 align-items-center">
                    <div class="col-12 col-xl-4">
                        <label class="m-0">Contributor name</label>
                        <b-form-input placeholder="Contributor Name" aria-label="Contributor Name" aria-labelledby="Contributor Name" 
                            type="text" :name="`ContributorName`" 
                            v-model="caseDetail.contributorName" size="sm" 
                            v-validate="'required'" :state="errors.has(`ContributorName`)?false:null"
                        ></b-form-input>
                    </div>                    
                    <div class="col-auto pt-25">
                        <b-form-checkbox name="is-show-public-site" aria-label="is-show-public-site" aria-labelledby="is-show-public-site" 
                            size="sm" v-model="caseDetail.isShowPublicSite" 
                        >
                            <div>
                                Do NOT show Contributor name on WTR public website
                            </div>
                        </b-form-checkbox>
                    </div>
                    <div class="col-12 col-xl-4 mt-5">
                        <label class="m-0">Editor name</label>
                        <b-form-input disabled placeholder="Editor Name" aria-label="Editor Name" aria-labelledby="Editor Name" 
                            type="text" :name="`EditorName`" 
                            v-model="caseDetail.editorName" size="sm" 
                        ></b-form-input>
                    </div>
                    <div class="col-auto pt-25">
                        <b-form-checkbox name="is-show-public-site-editor" aria-label="is-show-public-site-editor" aria-labelledby="is-show-public-site-editor" 
                            size="sm" v-model="caseDetail.isShowPublicSiteEditor" 
                        >
                            <div>
                                Do NOT show Editor name on WTR public website
                            </div>
                        </b-form-checkbox>
                    </div>
                </div>
    
                <header class="modal-header modal-subheader" style="padding: 1px 0 !important;" name="upload-modal-end"></header>
    
                <b-row v-show="slides.length" class="pb-10 pt-10 pt-xl-0 row">
                    <b-col class="d-flex justify-content-end align-items-baseline">
                        <div v-if="isUploadCaseProcess">
                            <wtr-loader class="mr-3 text-primary" loadingText=" Wait..." addText ></wtr-loader>
                        </div>
                        <b-button aria-label="upload" aria-labelledby="upload" :title="(!isEditCase)?$t('common.upload')  :$t('common.save')" size="sm" variant="primary" v-on:click="validateData" 
                            class="mr-2"
                            :class="isUploadCaseProcess?'disabled':''"
                            >
                            <span v-if="!isEditCase">{{ $t('common.upload') }} Case</span>
                            <span v-else>{{ $t('common.save') }} Changes</span>
                        </b-button>
                        <b-button aria-label="cancel" aria-labelledby="cancel" title="Cancel" size="sm" variant="outline-primary" v-on:click="cancel"
                            :class="isUploadCaseProcess?'disabled':''"
                        >
                            {{ $t('common.cancel') }}
                        </b-button>
                    </b-col>
                </b-row>
    
                <div>
                    <div class="fs-14 text-center">
                        Please send an email to <a href="mailto: support@worldtumorregistry.org"><span class="fw-600">support@worldtumorregistry.org</span></a>
                        if you encounter any issues while case creation or uploading/processing files.
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
    
<script>
import { cloneDeep, forEach, assign, filter, find, sortBy, orderBy } from "lodash";
import slidesMixin from "../../../mixins/slides-mixin";
import { getOnFileSelect } from "../../../helpers/slide-upload";
import { formatFileSize,formatFileSizeGB } from "../../../helpers/utilities";
import agesList from "../../../models/model-age";
import countryList from '../../../models/model-countries.js';
import WTRStorageIndicator from "../../../components/subscription/wtr-storage-indicator.vue";
import WTRSelectionDropdown from '../../../components/common/wtr-selection-dropdown.vue'
import WTRCascadeSelection from '../../../components/common/wtr-cascade-selection.vue'
import 'element-ui/lib/theme-chalk/index.css';
import * as folderTypes from "../../../constants/constants-folder";
import * as roleTypes from "../../../constants/constants-role";
import { mapGetters } from 'vuex';
import WTRImageUpload from '../../../components/common/wtr-image-upload.vue';

export default {
    name: "upload-slide",
    data() {
        return {
            ...folderTypes,
            ...roleTypes,
            tumorSiteId : 0,
            caseDetail:{
                caseNumber: '',
                tumorSite: null,
                tumorType: null,
                tumorSubType: null,
                caseNotes: '',
                sex: null,
                age: null,
                geographicRegion: null,
                country: null,
                state: '',
                environmentalSettings: [],
                familialDiseases: [],
                stage: null,
                tCategory: null,
                nCategory: null,
                mCategory: null,
                cytology: null,
                diagnosticFeatures:[],
                immunoHistoChemistries: [],
                genetics: [],
                specificCohort: null,
                contributorName: null,
                isShowPublicSite: null,
                states: [],
                isShowPublicSiteEditor: null,
                editorName: null,
                tumorSizes: [],
            },
            isLoadAllMetaData: false,
            allMetaData: {
                cytologies: [],
                environmentalSettings: [],
                familialDiseases: [],
                genetic: [],
                geographicRegions: [],
                diagnosticFeatures:[],
                immunoHistoChemistries: [],
                mCategories: [],
                nCategories: [],
                stages: [],
                tCategories: [],
                tumorSites: [],
                tumorTypes: [],
                tumorSubTypes: [],
                stainStudy: [],
                state: [],
                tumorSizes: [],
            },    
            countries: [], 
            ages: agesList,
            tumorTypes: [],
            tumorSubTypes: [],
            specificCohorts:[],
            
            isCheckValidation: false,
            slidesValidation: {
                genetics : [],
                states : [],
                diagnosticFeatures:[],
                immunoHistoChemistries : [],
                familialDiseases: [],
                environmentalSettings: [],
                stainStudy: [],
                tumorSizes: []
            },

            otherSection: null,
            storage: 0,
            slideData: cloneDeep(this.slideContent),
            slides: [],
            files: [],
            fileErrors: [],
            agree: false,
            excessStorage: 0,
            formatFileSize,
            showFileSupport: false,
            userDetail: null,
            isEditCase: false,
            isUploadCaseProcess: false,
            recycle: false,
            deletedSlideData: [],
            fileIndex: 0,
            isImageUploading: false,
            createdChildOptionsImmunoChemi: ['Positive', 'Negative'],
        };
    },
    mixins: [slidesMixin],
    props: {
        slideContent: Object,
        folderData: Object,
        params: Object,
    },
    components: {
        "wtr-storage-indicator": WTRStorageIndicator,
        "wtr-selection-dropdown": WTRSelectionDropdown,
        "wtr-cascade-selection": WTRCascadeSelection,
        "wtr-image-upload": WTRImageUpload,
    },
    computed: {
        ...mapGetters({
            showCaseSlides: "getShowCaseSlidesStatus",
            showCaseView: "getShowCaseViewStatus",
            selectedCaseNumber: "getSelectedCaseNumber",
        }),
        gender() {
            return [{
                    text: this.$t("common.unknown"),
                    value: this.SLIDE_UPLOAD_UNKNOWN,
                },
                {
                    text: this.$t("common.male"),
                    value: this.SLIDE_UPLOAD_MALE,
                },
                {
                    text: this.$t("common.female"),
                    value: this.SLIDE_UPLOAD_FEMALE,
                },
            ];
        },
        storageConsumed() {
            return this.$storageConsumed + this.storage;
        },
        canMoveToSlideLibrary() {
            return this.$canAccess("myslidebox", "canMoveToSlideLibrary");
        },
        userRole() {
            const loginUserRole = localStorage.getItem("userRole");
            return loginUserRole;
        },
        fileOrderOptions(){
            var fileOrderOptions = [];
            this.slides.forEach( (slide, index) => {
                const obj = { label: (index + 1).toString(), value: index + 1};
                fileOrderOptions.push(obj);
            });

            return fileOrderOptions;
        },
    },
    mounted() {
        this.init();
        this.isCheckValidation = false;
    },
    updated() {
        this.$nextTick(() => {
            this.$setWCAGVueEditorFixes();
        }); 
    },
    methods: {
        init() {
            this.getRequiredListData();
        },
        getSubscriptionDetails(data) {
            let userId = ''
            if (this.folderData.folderType === this.FOLDER_EDITORIALBOX) {    
                 userId = data.payload.contributorId;
            }
            else
            {
                userId = this.folderData.userId;
            }
            this.$api.checkUserSubscriptionFact(userId).then(
                (response) => {
                if (!this.$handleResponse(response)) return;
                const data = response.data.payload;
                if (data.size == null) {
                    data.size = 0;
                    data.totalSizeConsumed = 0;
                    data.label = "";
                    data.id = 0;
                }
                else
                {
                    const sizeLabel = formatFileSizeGB(data.size, "none");
                    data.size = sizeLabel;
                }
                const subScriptiondata = this.$session.updateSessionData({
                    subscriberInformation : data,
                });
                this.$store.dispatch('sessionDataAction', subScriptiondata);
                },
                (err) => {
                this.$handleError(err);
                }
            );
        },
        getRequiredListData() {
            this.getUserDetails();
            this.getUserProfile(localStorage.getItem("userId"));
            this.getOtherSlideSection();            
        },   
        getAllMetadataList(tumorSiteId) {
            this.$api.getAllMetadataListFact(tumorSiteId).then(
                (response) => {
                    var allDataList = response.data.payload;
                    if (allDataList != null) {
                        this.allMetaData = allDataList;

                        this.tumorTypes = this.createGroup(this.allMetaData.tumorTypes);                              
                        this.specificCohorts = this.allMetaData.specificCohorts;
                          
                        if (typeof this.slideData.metadata === 'undefined') {
                                 // metadata object does not exist
                                this.slideData.metadata = {}; // Create the 'metadata' property
                                this.slideData.metadata.caseDetailId = this.slideData.caseDetailId;
                        }
                        if (this.userRole === "GUEST" || this.userRole === "EDITOR" || this.userRole === "CHIEF EDITOR") {                            
                            this.slideData.metadata.geographicRegion = find(this.allMetaData.geographicRegions, (item) => item.id === this.userDetail.regionid);
                            this.caseDetail.geographicRegion = this.slideData.metadata.geographicRegion;
                            this.slideData.metadata.geographicRegionId = this.userDetail.regionid;
                            this.countries = filter(countryList, (item) => item.regionId == this.userDetail.regionid);    //fill country by regionid

                            this.countries.push({
                                "country": "Unknown",
                                "latitude": "",
                                "longitude": "",
                                "region": "",
                                "regionId": "",
                                "countryCode": ""
                            })
                        }

                        //set first tumorsite by deafult
                        if (this.slideData.metadata.caseDetailId) {
                            this.isEditCase = true;
                            this.getAllCaseDetailsById(this.slideData.metadata.caseDetailId);
                        }else{
                            this.caseDetail.tumorSite = this.allMetaData.tumorSites.find(obj => obj.id == this.tumorSiteId);
                            this.isLoadAllMetaData = true;
                        }    
                    }
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },    
        getOtherSlideSection() {
            this.$api.getSlideSectionsFact().then(
                (response) => {
                    let sections = response.data.payload;
                    this.otherSection = find(sections, (item) => item.name === "Others");
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },   
        getUserDetails() {
            this.$api.getUserDetailFact(this.$userData.id).then(
                (response) => {
                    this.userDetail = response.data;
                    this.tumorSiteId = this.userDetail.userTumorSites.id;
                    this.getAllMetadataList(this.tumorSiteId);
                    if(this.userDetail.editorId == null || this.userDetail.editorId == "")
                    {
                        this.getSetEditorDetails(localStorage.getItem("userId"));
                    }            
                    else
                    {
                        this.getSetEditorDetails(this.userDetail.editorId);
                    }        
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },   
        getUserProfile(userId){
            this.$api.getUserFact(userId).then(
                (response) => {
                    const userProfile = response.data;
                    this.caseDetail.contributorName =
                        userProfile.firstName.concat(
                        userProfile.middleName ? ' ' + userProfile.middleName : '',
                        userProfile.lastName ? ' ' + userProfile.lastName : ''
                    );
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getSetEditorDetails(userId){
            this.$api.getUserFact(userId).then(
                (response) => {
                    const userProfile = response.data;
                    this.caseDetail.editorName =
                        userProfile.firstName.concat(
                        userProfile.middleName ? ' ' + userProfile.middleName : '',
                        userProfile.lastName ? ' ' + userProfile.lastName : ''
                    );
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getAllCaseDetailsById(caseDetailId){
            this.$api.getAllCaseDetailsByIdFact(caseDetailId).then(
                (response) => {
                    if(response.data.status){
                        const caseDetailId = this.slideData.metadata.caseDetailId;
                        this.slideData.metadata = {};
                        this.slideData.metadata.id = 0;
                        this.slideData.metadata.caseDetailId = caseDetailId;
                        this.slideData.id = 0;
                        const payload = response.data.payload;

                        this.caseDetail = payload;                        

                        if(this.caseDetail.editorName == null || this.caseDetail.editorName == "")
                        {
                            if(this.caseDetail.contributorId !== null || this.caseDetail.contributorId !== "")
                            {
                                if(this.userDetail.userRole === 'GUEST')
                                {
                                     this.$api.getUserDetailFact(this.caseDetail.contributorId).then(
                                        (response) => {
                                            const userData = response.data;
                                            this.getSetEditorDetails(userData.editorId);
                                        },
                                        (error) => {
                                            this.$handleError(error);
                                        }
                                    ); 
                                    
                                }
                                else
                                {
                                    this.getSetEditorDetails(this.caseDetail.contributorId);
                                }
                            }
                        }
                        this.caseDetail.tumorType.value = this.caseDetail.tumorType.name;

                        this.getSlidesImagesByCaseSlideIds(payload.metadataList);
                        this.getAllTumorSubTypes(true);
                        this.getCountryByRegion(this.caseDetail.geographicRegion.id, true);                        
                        this.getSubscriptionDetails(response.data);
                        this.isLoadAllMetaData = true;
                    }
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getSlidesImagesByCaseSlideIds(metadataList) {
            let slideIds = [];          
            metadataList = orderBy(metadataList,["fileOrder"],["desc"])
            metadataList.forEach(metadata => {
                slideIds.push(parseInt(metadata.slideRefId));
            });
           
            this.$api.getSlideImagesByCaseSlideIdsFact(slideIds).then(
                (response) => {
                    if (!this.$handleResponse(response)) return;
                    const slideImages = response.data.payload;                    
                    this.showThumbnailUpload = true;
                    metadataList.forEach(metadata => {
                        if(!metadata.isDelete && !metadata.isRecycle){
                            metadata.resourceId =  slideImages.filter(t => t.id == metadata.slideRefId)[0]?.resourceId;
                            metadata.containerName = slideImages.filter(t => t.id == metadata.slideRefId)[0]?.containerName;
                            metadata.thumbnailPath = slideImages.filter(t => t.id == metadata.slideRefId)[0]?.thumbnailPath;
                            this.slides.unshift({
                                metadata: metadata,
                                name: metadata.name
                            });
                        }
                    });
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        toggleFileSupport() {
            this.showFileSupport = !this.showFileSupport;
        },   
        removeFile(index, slide) {
            if(slide.metadata?.id>0){
                this.deleteSlide(slide, index);
            }else{
                this.storage -= cloneDeep(this.slides[index]).fileSize;
                this.spliceSlide(index,slide);
            }
        },
        spliceSlide(index,slide){         
            this.files = this.files.filter(t=> t.fileIndex !== slide.metadata.fileIndex);
            this.slides.splice(index, 1);
            for (const key in this.slidesValidation) {
                this.slidesValidation[key].splice(index, 1);
            }

            this.slides.forEach((slide, index) => {
                slide.metadata.fileOrder = index + 1;
            });
        },
        thumbnailUploaded({ slide, response, status }) {
                if(status == 100)
                {                            
                    this.updateThumbnail(slide,response.uri);                 
                }
        },
        updateThumbnail(slide,thumbnailpath) {
           this.isLoading = true;
           this.$api.updateThumbnailFact(slide.metadata.slideRefId,thumbnailpath).then(
            (response) => {
                if (response.data) {
                    this.isLoading = false;
                    setTimeout(() => {
                        this.slides = [];
                        this.getSlidesImagesByCaseSlideIds(this.caseDetail.metadataList); 
                        if(!this.showCaseSlides)
                        {
                            this.refreshCaseData();
                        }
                        else
                        {
                            this.refreshSlideData();                                    
                        }
                    }, 4000);        
                }                
            },
            (error) => {
                this.isLoading = false;
                this.$handleError(error);
            }
            );
        },
        uploadFile(e) {
            this.showThumbnailUpload = false;
            this.fileErrors = [];
            const target = e.target || e.srcElement;
            const nonDigital = [
                this.SLIDE_DIGITALSLIDE,
                this.SLIDE_ZOOMIFY,
                this.SLIDE_RADIOLOGY,
                this.SLIDE_UNKNOWN,
            ];
            const files = sortBy(e.target.files, "size");

            forEach(files, (item) => {
                const ext = this.$getFileExtension(item.name);
                const extType = this.setFileType(ext, item.type);
                const fileSize = item.size;
                if (!fileSize) {
                    this.fileErrors.push({
                        name: item.name,
                        message: "You cannot upload an empty file",
                    });
                    return;
                }
                if(extType){
                    if (this.$checkStorage(this.storageConsumed + fileSize)) {
                    this.excessStorage += fileSize;
                    return;
                     }
                    this.storage += fileSize;
                }
                if (["jpg", "jpeg", "png", "pdf", "jfif"].includes(ext)) {
                    this.readFile(item, (valid) => {
                        if (!valid) {
                            this.fileErrors.push({
                                name: item.name,
                                message: "Content in this file does not match the file type",
                            });
                            return;
                        }
                        this.slideData = assign(this.slideData, {
                            name: item.name,
                            fileExtension: ext,
                            fileType: extType,
                            fileSize,
                            uploadType: 'WEB',
                            viewStatus: "ACTIVE",
                            viewType: 'PRIVATE',
                            dziStatus: nonDigital.includes(extType) ? 0 : 1,
                        });
                        item.fileIndex = this.fileIndex++;
                        this.files.push(item);
                        this.slideData.metadata.fileOrder = this.slides.length + 1;
                        this.slideData.metadata.fileIndex = item.fileIndex;
                        this.slides.push(cloneDeep(this.slideData));
                    });
                } else {
                    if (!extType) {
                        this.$toastr('error','File Format is not supported');                        
                        return;
                    }
                    this.slideData = assign(this.slideData, {
                        name: item.name,
                        fileExtension: ext,
                        fileType: extType,
                        fileSize,
                        uploadType: 'WEB',
                        viewStatus: "ACTIVE",
                        viewType: 'PRIVATE',
                        dziStatus: nonDigital.includes(extType) ? 0 : 1,
                    });
                    item.fileIndex = this.fileIndex++;
                    this.files.push(item);
                    this.slideData.metadata.fileOrder = this.slides.length + 1;
                    this.slideData.metadata.fileIndex = item.fileIndex;
                    this.slides.push(cloneDeep(this.slideData));
                }              
            });
            target.value = "";
        },
        createGroup(property){
            var group = this.sortedItemsAsc(property,'name');

            group.forEach(list => {
                list.value = list.name;
            });
            group = this.groupedTumorType(group);  

            return group;
        },
        getAllTumorTypes(tumorSiteId) {            
            this.$api.getAllTumortypesFact(tumorSiteId).then(
                (response) => {
                    this.tumorTypes = response.data.payload;
                    this.tumorTypes = this.createGroup(this.tumorTypes);

                    this.caseDetail.tumorType = null;
                    this.caseDetail.tumorSubType = null;
                    this.caseDetail.specificCohort = null;

                    if(this.isCheckValidation)
                    {
                        this.checkTumorTypeValidation();
                    }
                },
                (error) => {
                    this.$handleError(error);
                }
            );

            this.getAllSpecificCohorts(tumorSiteId);
        },
        getAllTumorSubTypes(isEdit) {
            if(this.isCheckValidation)
            {
                this.checkTumorTypeValidation();
            }

            let tumorType = this.caseDetail.tumorType;

            this.$api.getAllTumorSubTypesFact(tumorType.id).then(
                (response) => {
                    this.tumorSubTypes = response.data.payload;
                    if(!isEdit)
                    {
                        this.caseDetail.tumorSubType = null;
                    }
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getAllSpecificCohorts(id) {
            this.$api.getAllSpecificCohortsByTumorFact(id).then(
                (response) => {
                    if(response.data.status){
                        this.specificCohorts = response.data.payload;
                    }
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getCountryByRegion(regionId, isEdit=false) {
            this.countries = filter(countryList, (item) => item.regionId == regionId);    //fill country by regionid
            this.countries.push({
                "country": "Unknown",
                "latitude": "",
                "longitude": "",
                "region": "",
                "regionId": "",
                "countryCode": ""
            })
            if(!isEdit)
            {
                this.caseDetail.country = "";
            }
        },       
        
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async validateData() {
            var isError = false;
            this.isCheckValidation = true;

            await this.sleep(100);
            for (const key in this.slidesValidation) {   
                for (const slide of this.slidesValidation[key]){
                    isError = slide.some(obj => obj.error === true);   
                    if(isError) break;
                }
                if(isError) break;
            }

            const isTumorTypeSelected = this.checkTumorTypeValidation();
            

            this.$validator.validate().then((result) => {
                if (result && !isError && isTumorTypeSelected && !this.validateAllFileOrderDropdowns()) {
                    if (!this.agree) {
                        this.$toastr(
                            "error",
                            this.$t("slidebox.agreeToTheTerms", {
                                event: this.$t( this.slideData.id ? "slidebox.updating_sm" : "slidebox.uploading_sm"),
                            })
                        );
                        return;
                    }

                    this.addTumorsiteId(this.caseDetail.environmentalSettings);
                    this.addTumorsiteId(this.caseDetail.familialDiseases);
                    this.addTumorsiteId(this.caseDetail.diagnosticFeatures);
                    this.addTumorsiteId(this.caseDetail.immunoHistoChemistries);
                    this.addTumorsiteId(this.caseDetail.genetics);
                    // if(this.caseDetail.states.length > 0)
                    // {
                    //     this.addTumorsiteId(this.caseDetail.states);
                    // }
                    this.addTumorsiteId(this.caseDetail.tumorSizes);

                    if (this.folderData.folderType !== this.FOLDER_EDITORIALBOX)
                    {
                        this.caseDetail.contributorId = 0;
                    }
                    
                    if(!this.folderData.id === 0)
                    this.caseDetail.folderId = this.folderData.id;

                    if(this.folderData.id === 0)
                    {                        
                        // eslint-disable-next-line vue/no-mutating-props
                        this.folderData.id = this.caseDetail.folderId;
                    }
                    
                    if(this.isEditCase){
                        this.isUploadCaseProcess = true;
                        if (this.folderData.folderType !== this.FOLDER_EDITORIALBOX)
                        {
                            this.caseDetail.contributorId = this.folderData.userId;
                        }
                        this.updateCaseData(this.caseDetail);
                    } else {
                        this.addCaseData(this.caseDetail);
                    }
                } else {
                    this.$toastr("error", this.$t("notes.fillAllValidDetails"));
                }
            });
        },
        addCaseData(caseData) {
            this.$api.addCaseDataFact(caseData).then(
                (response) => {
                    if(response.data.status){
                        this.metadataSave(response.data.payload);
                    }
                },
                (error) => {
                    this.isUploadCaseProcess = false;
                    this.$handleError(error);
                }
            );
        },
        updateCaseData(caseData) {
            this.$api.updateMetadataFact(caseData).then(
                (response) => {
                    if(response.data.status){
                          //update status in slide DB                        
                        this.deletedSlideData.forEach(selectedArray => {                            
                            const matchingData = this.caseDetail.metadataList.find(data => data.slideRefId === selectedArray.id);
                            if (matchingData) {
                                if (matchingData.isDelete) {
                                this.deleteFileStatus([selectedArray], this.FOLDER_DELETE, "");
                                }
                                if (matchingData.isRecycle) {
                                this.deleteFileStatus([selectedArray], this.FOLDER_RECYCLE, "");
                                }
                            }
                        });

                        this.slides = this.slides.filter(slide => slide.metadata.id == 0);
                        this.$toastr( "success", this.$t("notes.updateSuccessMsg", { text: "Case Detail" }) );
                        if(this.slides.length>0){
                            this.metadataSave(response.data.payload);
                        }
                        else{
                            setTimeout(() => {
                                if(!this.showCaseSlides)
                                {
                                    this.refreshCaseData();
                                }
                                else
                                {
                                    this.refreshSlideData();                                    
                                }
                            }, 2000); 
                            this.closeModel();
                        }
                    }
                },
                (error) => {
                    this.isUploadCaseProcess = false;
                    this.$handleError(error);
                }
            );
        },
        metadataSave(metadata){
            this.slides.forEach(slide =>{
                slide.metadata = {
                    ...slide.metadata,
                    ...(metadata && Object.entries(metadata)
                        .reduce((acc, [key, value]) => {
                            if (value !== null) {
                                acc[key] = value;
                            }
                            return acc;
                        }, {}))
                };
                slide.metadata.caseDetailId = metadata.id;
                if (typeof slide.slideMetadata === 'undefined') {
                    slide.slideMetadata = {}; 
                    slide.slideMetadata.age = null;
                    slide.slideMetadata.clinicalInfo = null;
                    slide.slideMetadata.secondDiagnosis = null;
                    slide.slideMetadata.siteOROrgan = null;
                    slide.slideMetadata.stainORstudy = null;
                    slide.slideMetadata.strainORbreed = null;
                    slide.slideMetadata.gender = "UNKNOWN";
                }
                slide.slideMetadata.id = 0;
            });
            const fileData = [];
            forEach(this.files, (item, index) => {
                this.slides[index].metadata.name = this.slides[index].name; //set metadata name
                this.slides[index].section = this.otherSection; //set others section for slide
                this.slides[index].slideMetadata["diagnosis"] =
                    this.slides[index].metadata.tumorSite.name + "-" +
                    this.slides[index].metadata.tumorType.name + "-" +
                    this.slides[index].metadata.tumorSubType.name; //set diagnosis with tumor site + type + subtype combination

                fileData.push({
                    file: item,
                    folderName: this.folderData.name,
                    folderData: this.folderData,
                    data: cloneDeep(
                        assign(this.slides[index], {
                            folderId: this.folderData.id === 0 ? this.caseDetail.folderId : this.folderData.id,
                        })
                    ),
                });
            });
            const { $setModalAction } = this;
            getOnFileSelect()({
                fileData,
                cb: () => {
                    this.$updateSubscriptionStorageData(this.storageConsumed);
                    $setModalAction({
                        show: false,
                    });
                },
            });
        },
        resetSlide(hide) {
            if (!hide) return;
            this.slideData = null;
            this.cancel();
        },
        cancel() {
            this.$setModalAction({
                show: false,
            });
        },
        sortedItemsAsc(items, key)
        {
            if (items)
            {
                items = JSON.parse(JSON.stringify(items));

                const unknownIndex = items.findIndex(item => item[key].toLowerCase() === "unknown");
                if (unknownIndex !== -1)
                {
                    const unknownCountry = items.splice(unknownIndex, 1)[0];
                    items.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()));
                    items.push(unknownCountry);
                }
                else
                {
                    items.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()));
                }
                return items;
            }
        },
        sortedItemsCytology(items, key)
        {
            if (items)
            {
                items = JSON.parse(JSON.stringify(items));
                const customSort = (a, b) =>
                {
                    const getCategoryOrder = (item) =>
                    {
                        const categoryMatchA = item[key].match(/^([^0-9]+)\s*([0-9]*)/);
                        const categoryA = categoryMatchA ? categoryMatchA[1].trim() : item[key].trim();
                        const numberA = categoryMatchA ? parseInt(categoryMatchA[2]) : 0;

                        switch (categoryA) {
                        case 'Bethesda TBS':
                            return 0 * 1000 + numberA;
                        case 'Australian':
                            return 1 * 1000 + numberA;
                        case 'British Thy':
                            return 2 * 1000 + numberA;
                        case 'Italian TIR':
                            return 3 * 1000 + numberA;
                        case 'Japanese':
                            return 4 * 1000 + numberA;
                        default:
                            return 5 * 1000 + numberA;
                        }
                    };

                    const categoryOrderA = getCategoryOrder(a);
                    const categoryOrderB = getCategoryOrder(b);

                    return categoryOrderA - categoryOrderB;
                };

                items.sort(customSort);
                return items;
            }            
         },
        checkTumorTypeValidation()
        {
            let isItemSelected = true;

            if (this.caseDetail.tumorType != null)
            {   
                window.$('.cus-placeholder').find('.el-select').find('.el-input').find('input').removeClass('is-error');                    
            }
            else
            {                    
                window.$('.cus-placeholder').find('.el-select').find('.el-input').find('input').addClass('is-error');
                isItemSelected = false;
            }

            return isItemSelected;
        },
        addTumorsiteId(prop){
            prop.forEach(obj => {
                if(obj.onFlyEntry){
                    obj.tumorSiteId = this.caseDetail.tumorSite.id;
                }
            });
        },
        validateFileOrderDropdowns(dataIndex) {
            const selectedValues = this.slides.map(slide => slide.metadata.fileOrder);
            const selectedValue = this.slides[dataIndex].metadata.fileOrder;
            const isDuplicate = selectedValues.filter(value => value === selectedValue).length > 1;

            return isDuplicate ? true : false;
        },
        validateAllFileOrderDropdowns() {
            const selectedValues = this.slides.map(slide => slide.metadata.fileOrder);
            const uniqueValues = [...new Set(selectedValues)];
            return selectedValues.length !== uniqueValues.length;
        },
        closeModel(){
            this.$setModalAction({ show: false });
        },
        deleteSlide(slide, index){
            const params = {
                page: 0,
                size: 1,
                sort: "id,desc"
            };

            const slideId = slide.metadata.slideRefId;
            this.$api.getSlidesByCaseSlideIdsFact(slideId,params).then(
                response => {
                    if (!this.$handleResponse(response)) return;                 
                    const slideData = response.data.payload.content;
                     this.getCaseDetailsById(slide.metadata,index,slideData);
                },
            );
        },
        getCaseDetailsById(metadata,index,slideData){
            this.$api.getAllCaseDetailsByIdFact(metadata.caseDetailId).then(
                (response) => {
                    if(response.data.status){
                        const payload = response.data.payload;
                        this.getUserDetailsById(index,slideData,payload.contributorId)
                    }
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        getUserDetailsById(index,slideData,contributorId)
        {
            this.$api.getUserDetailFact(contributorId).then(
            (response) => {
            const payload = response.data;            
            let buttons = {};
            if(payload.userRole === this.ROLE_GUEST)
            {
                buttons = 
                {
                    cancel: true,
                    delete:
                    {
                        text: this.$t("common.delete"),
                        value: this.FOLDER_DELETE,
                        className: "bg-danger",
                    },
                }
            }
            else
            {
                buttons =
                {
                    cancel: true,
                    recycle:
                    {
                        text: this.$t("slidebox.MoveRecycleBin"),
                        value: this.FOLDER_RECYCLE,
                        className: "bg-secondary",
                    },
                    delete:
                    {
                        text: this.$t("common.delete"),
                        value: this.FOLDER_DELETE,
                        className: "bg-danger",
                    },
                };
                } 
                this.$deleteConfirmation(                   
                    this.$t("slidebox.slidePermanentDeleteMsg"),
                    (val) => {
                        slideData.forEach(result => {
                            const matchingData = this.caseDetail.metadataList.find(data => data.slideRefId === result.id);
                            if (this.recycle || (val && val === this.FOLDER_DELETE)) {                                
                                this.$updateSubscriptionStorageData(this.$storageConsumed - result.fileSize);
                                if (matchingData) matchingData.isDelete = true;
                                  this.spliceSlide(index);
                            } else {
                                if (matchingData) matchingData.isRecycle = true;
                                  this.spliceSlide(index);
                            }
                        });
                        this.deletedSlideData = [...this.deletedSlideData, ...slideData];
                    },
                    "",
                    !this.recycle ? buttons : null
                );
            },
            (error) => {
                this.$handleError(error);
            }
            ); 
        },
        deleteFileStatus(data, status, successCb) {
            this.$api.deleteFilesFact(data, status).then(
                (response) => {
                    if (!this.$handleResponse(response)) return;
                    if (successCb) successCb(response.data.payload);
                },
                (error) => {
                    this.$handleError(error);
                }
            );
        },
        refreshCaseData()
        {
            if (this.folderData.folderType === this.FOLDER_EDITORIALBOX)
            {
                // eslint-disable-next-line vue/no-mutating-props
                this.folderData.id = 0; 
            }
            const eventSend = new CustomEvent("callGetAllCases", {
            detail: {
                filtertype: null,
                filtervalue: null,
                page: 0,
                size:8,
                sort:"id,desc",
                searchEditorId: this.params.searchEditorId,
                searchGeographicRegionId: this.params.searchGeographicRegionId,
                searchReviewStatusId: this.params.searchReviewStatusId,
                searchSpecificCohortId: this.params.searchSpecificCohortId,
                search : this.params.search
             }
            });
            window.dispatchEvent(eventSend);
        },   
        refreshSlideData()
        {
            const eventSend = new CustomEvent("callGetAllSlides", {
            detail: {
                caseNumber: this.selectedCaseNumber,                
              }
            });
            window.dispatchEvent(eventSend);
        },      
    },
};
</script>
